import { BUDGETAPP_DELETE_BUDGET_CATEGORY_ACTIONTYPE } from '../../actionTypes';

export interface DeleteBudgetCategoryAction {
	readonly type: typeof BUDGETAPP_DELETE_BUDGET_CATEGORY_ACTIONTYPE;
	budgetId: string;
	categoryId: string;
}

export const deleteBudgetCategory = (
	budgetId: string,
	categoryId: string,
): DeleteBudgetCategoryAction => ({
	type: BUDGETAPP_DELETE_BUDGET_CATEGORY_ACTIONTYPE,
	budgetId,
	categoryId,
});

export default deleteBudgetCategory;
