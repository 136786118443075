
import { BudgetAppState } from '../../storeModel';
import { DeleteTransactionListAction } from './action';

export const reduceDeleteTransactionList = (
	prevState: BudgetAppState,
	action: DeleteTransactionListAction,
): BudgetAppState => {

	const newState = {...prevState};

	newState.transactionLists = (prevState.transactionLists
		.filter(list => (list.transactionListId !== action.transactionListId)));

	return newState;
}
