
import { globalDialogInitialState } from '../../state';
import { BudgetAppState } from '../../storeModel';
import { CloseGlobalDialogAction } from './action';

export const reduceCloseGlobalDialog = (
	prevState: BudgetAppState,
	action: CloseGlobalDialogAction
): BudgetAppState => {
	return {...prevState,
		globalDialogState: {...globalDialogInitialState},
	};
}