import { camelCase } from 'lodash';

export function objToCamel<T>(obj: T): T {
	if (Array.isArray(obj)) {
		return obj.map((v) => objToCamel(v)) as unknown as T;
	} else if (typeof obj === 'object') {
		return Object.keys(obj).reduce((result, key) => {
			// Don't transform uuid, will break stuff
			if (key === 'UUID') {
				return {
					...result,
					[key]: objToCamel((obj as any)[key]),
				};
			}
			if ((obj as any)[key] === null) {
				return {
					...result,
					[camelCase(key)]: null,
				};
			}
			if ((obj as any)[key] === undefined) {
				return {
					...result,
					[camelCase(key)]: undefined,
				};
			}
			return {
				...result,
				[camelCase(key)]: objToCamel((obj as any)[key]),
			};
		}, {}) as unknown as T;
	}
	return obj;
}
