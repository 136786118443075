import React, { FunctionComponent } from 'react';
import {
	Header,
	HeaderContainer,
	HeaderName,
	HeaderNavigation,
	HeaderMenuButton,
	HeaderMenuItem,
	// HeaderGlobalBar,
	// HeaderGlobalAction,
	SkipToContent,
	SideNav,
	SideNavItems,
	HeaderSideNavItems,
} from 'carbon-components-react';

// import {
// 	Switcher20,
// 	Notification20,
// 	UserAvatar20,
// } from '@carbon/icons-react';

import './TopBar.scss';
import { BudgetAppState } from '../../redux/storeModel';
import { connect } from 'react-redux';

type TopBarProps = ReturnType<typeof mapStateToProps>;

const TopBar: FunctionComponent<TopBarProps> = (props) => {
	return (
		<HeaderContainer
			render={({ isSideNavExpanded, onClickSideNavExpand }) => (
				<Header aria-label="Header">
					<SkipToContent />
					<HeaderMenuButton
						aria-label="Open menu"
						onClick={onClickSideNavExpand}
						isActive={isSideNavExpanded}
					/>
					<HeaderName prefix="" href="/#/">
						Simple Budget
					</HeaderName>
					<HeaderNavigation aria-label="Navigation">
						<HeaderMenuItem href="/#/budget">Budget</HeaderMenuItem>
						{props.numBudgets > 0 ? (
							<HeaderMenuItem href="/#/transaction">Transaction</HeaderMenuItem>
						) : null}
					</HeaderNavigation>
					<SideNav
						aria-label="Side navigation"
						expanded={isSideNavExpanded}
						isPersistent={false}
					>
						<SideNavItems>
							<HeaderSideNavItems>
								<HeaderMenuItem href="/#/budget">Budget</HeaderMenuItem>
							</HeaderSideNavItems>
						</SideNavItems>
					</SideNav>
					{/* <HeaderGlobalBar>
						<HeaderGlobalAction aria-label="Notifications">
							<Notification20 />
						</HeaderGlobalAction>
						<HeaderGlobalAction aria-label="User Avatar">
							<UserAvatar20 />
						</HeaderGlobalAction>
						<HeaderGlobalAction tooltipAlignment='end' aria-label="App Switcher">
							<Switcher20 />
						</HeaderGlobalAction>
					</HeaderGlobalBar> */}
				</Header>
			)}
		></HeaderContainer>
	);
};

const mapStateToProps = (state: BudgetAppState) => {
	return { numBudgets: state.budgets.length };
};

export default connect(mapStateToProps)(TopBar);
