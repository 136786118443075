import {
	calcAnnualValue,
	calcQuarterlyValue,
	calcMonthlyValue,
	calcFortnightlyValue,
	calcWeeklyValue,
} from '../../utils/BudgetUtils';
import {
	FrequencyOptions,
	BudgetTotals,
	Budget,
	ItemSpendType,
} from '../Model';

const selectBudgetTotals = (
	budget: Budget,
	frequency: FrequencyOptions
): BudgetTotals => {
	if (budget === undefined) {
		throw new Error('Budget not found!');
	}

	let total = 0;
	let incomeTotal = 0;
	let savingsTotal = 0;
	let expenseTotal = 0;

	const categoryTotals = new Map<string, number>();

	budget.categories.forEach((category) => {
		let categoryTotal = 0;

		category.items.forEach((item) => {
			let value = 0;

			switch (frequency) {
				case FrequencyOptions.FinancialYear:
				case FrequencyOptions.Annually: {
					value = calcAnnualValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
				case FrequencyOptions.Quarterly: {
					value = calcQuarterlyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
				case FrequencyOptions.Monthly: {
					value = calcMonthlyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
				case FrequencyOptions.Fortnightly: {
					value = calcFortnightlyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
				case FrequencyOptions.Weekly: {
					value = calcWeeklyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
			}

			if (category.isIncome || item.spendType === ItemSpendType.Income) {
				categoryTotal += value;
				incomeTotal += value;
			} else if (item.spendType === ItemSpendType.Saving) {
				categoryTotal -= value;
				savingsTotal += value;
			} else {
				categoryTotal -= value;
				expenseTotal += value;
			}
		});

		categoryTotals.set(category.UUID, categoryTotal);
	});

	categoryTotals.forEach((categoryTotal) => {
		total += categoryTotal;
	});

	const surplus = incomeTotal - expenseTotal;

	return {
		total,
		incomeTotal,
		expenseTotal,
		savingsTotal,
		surplus,
		categoryTotals,
	};
};

export default selectBudgetTotals;
