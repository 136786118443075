import { v4 as uuidv4 } from 'uuid';
import {
	Budget,
	Category,
	BudgetItem,
	FrequencyOptions,
	TransactionList,
	frequencyIdToStringMap,
	ItemSpendType,
} from '../redux/Model';
import { saveAs } from 'file-saver';
import { budgetApiInstance } from '../api';

/**
 *
 * @returns
 */
export const createBudget = (): Budget => {
	return {
		UUID: uuidv4(),
		name: 'Untitled Budget',
		categories: [createBudgetCategory()],
	};
};

/**
 *
 * @returns
 */
export const createBudgetCategory = (): Category => {
	return {
		UUID: uuidv4(),
		name: 'Untitled Category',
		total: 0,
		isIncome: false,
		items: [createBudgetItem()],
	};
};

/**
 *
 * @returns
 */
export const createBudgetItem = (): BudgetItem => {
	return {
		UUID: uuidv4(),
		name: 'Untitled Item',
		value: 0,
		frequency: FrequencyOptions.Annually,
		spendType: ItemSpendType.Unset,
	};
};

export const convertFrequencyToNumber = (frequency: FrequencyOptions) => {
	let freqNum = -1;
	Object.keys(FrequencyOptions).every((option, index) => {
		if (frequency === option) {
			freqNum = index;
			return false;
		}
		return true;
	});
	return freqNum;
};

/**
 *
 * @param budget
 * @param transactionLists
 */
export const saveBudget = (
	budget: Budget,
	transactionLists: Array<TransactionList>
) => {
	// TODO save transaction lists!
	if (budget.fromDB) {
		// We need to convert frequency to number for the backend
		const budgetToSave = { ...budget };
		budgetToSave.categories = budgetToSave.categories.map((category) => {
			return {
				...category,
				items: category.items.map((item): BudgetItem => {
					if (typeof item.frequency === 'string') {
						return {
							...item,
							frequency: convertFrequencyToNumber(item.frequency),
						};
					}
					return { ...item };
				}),
			};
		});
		budgetApiInstance.saveBudget(budgetToSave);
	} else {
		const output = JSON.stringify({ budget, transactionLists }, null, 2);

		const blob = new Blob([output], { type: 'application/json' });

		saveAs(blob, 'SimpleBudget.json');
	}
};

/**
 * Calculates the annual value based on the value and associated frequency provided.
 * @param value
 * @param frequency
 * @returns
 */
export const calcAnnualValue = (
	value: number,
	frequency: FrequencyOptions
): number => {
	switch (frequency) {
		case FrequencyOptions.Annually: {
			return value;
		}
		case FrequencyOptions.Quarterly: {
			return value * 4;
		}
		case FrequencyOptions.Monthly: {
			return value * 12;
		}
		case FrequencyOptions.Fortnightly: {
			return value * 26.0714;
		}
		case FrequencyOptions.Weekly: {
			return value * 52.1429;
		}
		default: {
			throw new Error('Frequency entered is not supported.');
		}
	}
};

/**
 * Calculates the quarterly value based on the value and associated frequency provided.
 * @param value
 * @param frequency
 * @returns
 */
export const calcQuarterlyValue = (
	value: number,
	frequency: FrequencyOptions
): number => {
	switch (frequency) {
		case FrequencyOptions.Annually: {
			return value / 4;
		}
		case FrequencyOptions.Quarterly: {
			return value;
		}
		case FrequencyOptions.Monthly: {
			return value * 3;
		}
		case FrequencyOptions.Fortnightly: {
			return value * 6.5178571428571;
		}
		case FrequencyOptions.Weekly: {
			return value * 13.035714286;
		}
		default: {
			throw new Error('Frequency entered is not supported.');
		}
	}
};

/**
 * Calculates the monthly value based on the value and associated frequency provided.
 * @param value
 * @param frequency
 * @returns
 */
export const calcMonthlyValue = (
	value: number,
	frequency: FrequencyOptions
): number => {
	switch (frequency) {
		case FrequencyOptions.Annually: {
			return value / 12;
		}
		case FrequencyOptions.Quarterly: {
			return value / 3;
		}
		case FrequencyOptions.Monthly: {
			return value;
		}
		case FrequencyOptions.Fortnightly: {
			return value * 2.17262;
		}
		case FrequencyOptions.Weekly: {
			return value * 4.34524;
		}
		default: {
			throw new Error('Frequency entered is not supported.');
		}
	}
};

/**
 * Calculates the fortnightly value based on the value and associated frequency provided.
 * @param value
 * @param frequency
 * @returns
 */
export const calcFortnightlyValue = (
	value: number,
	frequency: FrequencyOptions
): number => {
	switch (frequency) {
		case FrequencyOptions.Annually: {
			return value / 26.0714;
		}
		case FrequencyOptions.Quarterly: {
			return value / 6.5178571428571;
		}
		case FrequencyOptions.Monthly: {
			return value / 2.17262;
		}
		case FrequencyOptions.Fortnightly: {
			return value;
		}
		case FrequencyOptions.Weekly: {
			return value * 2;
		}
		default: {
			throw new Error('Frequency entered is not supported.');
		}
	}
};

/**
 * Calculates the weekly value based on the value and associated frequency provided.
 * @param value
 * @param frequency
 * @returns
 */
export const calcWeeklyValue = (
	value: number,
	frequency: FrequencyOptions
): number => {
	switch (frequency) {
		case FrequencyOptions.Annually: {
			return value / 52.1429;
		}
		case FrequencyOptions.Quarterly: {
			return value / 13.035714286;
		}
		case FrequencyOptions.Monthly: {
			return value / 4.34524;
		}
		case FrequencyOptions.Fortnightly: {
			return value / 2;
		}
		case FrequencyOptions.Weekly: {
			return value;
		}
		default: {
			throw new Error('Frequency entered is not supported.');
		}
	}
};

/**
 *
 * @param frequency
 * @returns
 */
export const selectFrequencySuffix = (frequency: FrequencyOptions): string => {
	switch (frequency) {
		case FrequencyOptions.Annually: {
			return 'per year';
		}
		case FrequencyOptions.Quarterly: {
			return 'per quarter';
		}
		case FrequencyOptions.Monthly: {
			return 'per month';
		}
		case FrequencyOptions.Fortnightly: {
			return 'per fortnight';
		}
		case FrequencyOptions.Weekly: {
			return 'per week';
		}
	}

	return '';
};

/**
 * Find a budget category by its ID.
 */
export const lookupBudgetCategory = (
	categories: Array<Category>,
	Id: string
) => {
	return categories.find((category) => {
		return category.UUID === Id;
	});
};

/**
 * Find a budget item by its ID.
 * @param items
 * @param Id
 * @returns
 */
export const lookupBudgetItem = (items: Array<BudgetItem>, Id: string) => {
	return items.find((item) => {
		return item.UUID === Id;
	});
};

export const savedFrequencyToLoaded = (
	frequency: string | number
): FrequencyOptions => {
	if (typeof frequency === 'number') {
		return frequencyIdToStringMap[frequency] as FrequencyOptions;
	} else {
		switch (frequency) {
			case 'Weekly': {
				return FrequencyOptions.Weekly;
			}
			case 'Fortnightly': {
				return FrequencyOptions.Fortnightly;
			}
			case 'Monthly': {
				return FrequencyOptions.Monthly;
			}
			case 'Quarterly': {
				return FrequencyOptions.Quarterly;
			}
			case 'Annually': {
				return FrequencyOptions.Annually;
			}

			default: {
				throw new Error(`Frequency ${frequency} not recognised`);
			}
		}
	}
};

export const savedItemTypeToLoaded = (itemType: string): ItemSpendType => {
	switch (itemType) {
		case 'need': {
			return ItemSpendType.Need;
		}
		case 'want': {
			return ItemSpendType.Want;
		}
		case 'saving': {
			return ItemSpendType.Saving;
		}
		case 'income': {
			return ItemSpendType.Income;
		}
		default: {
			return ItemSpendType.Unset;
		}
	}
};
