import {
	DataTable,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableHeader,
	TableRow,
	TableSelectRow,
	TableSelectRowProps,
} from 'carbon-components-react';
import { useEffect, useState } from 'react';
import { budgetApiInstance } from '../../api';
import { BudgetList } from '../../redux/Model';
import { GlobalModalComponentProps } from '../../redux/storeModel';

const tableHeaders = [
	{
		key: 'name',
		header: 'Name',
	},
	{
		key: 'id',
		header: 'ID',
	},
];

interface LoadFromDatabaseModalProps extends GlobalModalComponentProps {
	isOpen: boolean;
	onRequestSubmit: (budgetId: string) => void;
	onRequestClose: () => void;
}

const LoadFromDatabaseModal: React.FC<LoadFromDatabaseModalProps> = (props) => {
	const [budgetList, setBudgetList] = useState<BudgetList | null>(null);
	const [selectedBudget, setSelectedBudget] = useState<string | null>(null);

	const loadData = () => {
		return budgetApiInstance.listBudgets();
	};

	useEffect(() => {
		if (!budgetList && props.isOpen) {
			(async () => {
				setBudgetList((await loadData()).data);
			})();
		}
	}, [budgetList, props.isOpen]);

	return (
		<Modal
			open={props.isOpen}
			onRequestSubmit={() => {
				if (selectedBudget) {
					props.onRequestSubmit(selectedBudget);
				}
			}}
			onRequestClose={props.onRequestClose}
			modalHeading="Load Budget"
			primaryButtonText="Load"
			primaryButtonDisabled={selectedBudget === null}
		>
			<DataTable
				rows={budgetList ? budgetList : []}
				headers={tableHeaders}
				radio
				render={({
					rows,
					headers,
					getTableProps,
					getRowProps,
					getHeaderProps,
					getSelectionProps,
					selectedRows,
				}) => {
					return (
						<TableContainer>
							<Table {...getTableProps()}>
								<TableHead>
									<TableRow>
										<th scope="col" />
										{headers.map((header) => (
											<TableHeader {...getHeaderProps({ header })}>
												{header.header}
											</TableHeader>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map((row, index) => {
										// Appease typescript, weird compiler error due to type def
										const selectionProps = getSelectionProps({
											row,
										}) as TableSelectRowProps;
										const onSelect = selectionProps.onSelect;
										selectionProps.onSelect = (e) => {
											// TODO set state for use in callback
											setSelectedBudget(row.id);
											onSelect(e);
										};
										return (
											<TableRow {...getRowProps({ row })}>
												<TableSelectRow {...selectionProps} />
												{row.cells.map((cell) => (
													<TableCell key={cell.id}>{cell.value}</TableCell>
												))}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					);
				}}
			></DataTable>
		</Modal>
	);
};

export default LoadFromDatabaseModal;
