import {
	calcAnnualValue,
	calcFortnightlyValue,
	calcMonthlyValue,
	calcQuarterlyValue,
	calcWeeklyValue,
} from '../../utils/BudgetUtils';
import {
	Category,
	FrequencyOptions,
	ItemSpendType,
	TransactionList,
} from '../Model';

const UNCATEGORISED_ID = 'uncategorised';

const selectTransactionTotals = (
	transactionList: TransactionList,
	frequency: FrequencyOptions,
	categories?: Array<Category>
) => {
	let total = 0;
	let incomeTotal = 0;
	let savingsTotal = 0;
	let expenseTotal = 0;

	const categoryTotals = new Map<string, number>();
	const dummyCategory: Category = {
		UUID: UNCATEGORISED_ID,
		isIncome: false,
		items: [],
		name: UNCATEGORISED_ID,
		total: 0,
	};

	const finalCategories = categories
		? categories.concat([dummyCategory])
		: [dummyCategory];

	transactionList.transactions.forEach((transaction) => {
		let categoryTotal = 0;
		const transactionCategoryId = transaction.categoryId
			? transaction.categoryId
			: UNCATEGORISED_ID;
		let category = finalCategories.find(
			(category) => category.UUID === transaction.categoryId
		);
		if (!category) {
			category = dummyCategory;
		}
		const amount = Number.parseFloat(transaction.amount);

		let value;

		// Adjust values based on the frequency (yearly, monthly etc.) to display.
		switch (frequency) {
			case FrequencyOptions.Annually: {
				value = calcAnnualValue(amount, frequency);
				break;
			}
			case FrequencyOptions.Quarterly: {
				value = calcQuarterlyValue(amount, frequency);
				break;
			}
			case FrequencyOptions.Monthly: {
				value = calcMonthlyValue(amount, frequency);
				break;
			}
			case FrequencyOptions.Fortnightly: {
				value = calcFortnightlyValue(amount, frequency);
				break;
			}
			case FrequencyOptions.Weekly: {
				value = calcWeeklyValue(amount, frequency);
				break;
			}
			default: {
				throw new Error('Frequency selected is not supported.');
			}
		}

		const itemSpendType =
			category.items.find((i) => i.UUID === transaction.itemId)?.spendType ??
			ItemSpendType.Unset;

		if (category.isIncome || itemSpendType === ItemSpendType.Income) {
			categoryTotal += value;
			incomeTotal += value;
		} else if (itemSpendType === ItemSpendType.Saving) {
			categoryTotal -= value;
			savingsTotal += value;
		} else {
			categoryTotal -= value;
			expenseTotal += value;
		}

		let currCategoryTotal = categoryTotals.get(transactionCategoryId);
		if (!currCategoryTotal) {
			currCategoryTotal = 0;
		}
		categoryTotals.set(
			transactionCategoryId,
			currCategoryTotal + categoryTotal
		);
	});

	categoryTotals.forEach((categoryTotal) => {
		total += categoryTotal;
	});

	// Want to display savings as positive number, but savings show as negative in transactions
	savingsTotal *= -1;

	const balance = incomeTotal - savingsTotal - expenseTotal * -1;

	return {
		total,
		incomeTotal,
		expenseTotal,
		savingsTotal,
		balance,
		categoryTotals,
	};
};

export default selectTransactionTotals;
