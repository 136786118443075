import { BudgetAppState } from '../../storeModel';
import { UpdateBudgetNameAction } from './action';

const reduceUpdateBudgetName = (
	prevState: BudgetAppState,
	action: UpdateBudgetNameAction
): BudgetAppState => {
	const newBudgetList = [...prevState.budgets];

	const budgetToUpdate = newBudgetList.findIndex(
		(budget) => budget.UUID === action.budgetId
	);

	if (budgetToUpdate === -1) {
		throw new Error('Budget whose name to update not found!');
	}

	newBudgetList[budgetToUpdate] = {
		...newBudgetList[budgetToUpdate],
		name: action.name,
	};

	return {
		...prevState,
		budgets: newBudgetList,
	};
};

export default reduceUpdateBudgetName;
