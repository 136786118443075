import './TransactionChart.scss';

import {
	Budget,
	Category,
	FrequencyOptions,
	TransactionList,
} from '../../redux/Model';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from 'recharts';
import { useEffect, useState } from 'react';
import {
	findCategoryIndex,
	findBudget,
} from '../../redux/reducers/reducerUtils';
import { Button } from 'carbon-components-react';
import { BudgetAppState } from '../../redux/storeModel';
import { connect } from 'react-redux';
import selectBudgetTotals from '../../redux/selectors/selectBudgetTotals';
import selectBudgetItemDefinitions from '../../redux/selectors/selectBudgetItemDefinitions';
import selectTransactionTotals from '../../redux/selectors/selectTransactionTotals';
import {
	computeDrilldownTransactionChart,
	computeTransactionChart,
} from './ComputeTransactionChart';

interface OwnProps {
	transactionList: TransactionList;
	totals: ReturnType<typeof selectTransactionTotals>;
	frequency: FrequencyOptions;
}

export interface TransactionChartElement {
	name: string;
	total: number;
	transactionTotal: number;
	id: string;
}

export type BudgetChartProps = OwnProps & ReturnType<typeof mapStateToProps>;

const TransactionChart: React.FC<BudgetChartProps> = (props) => {
	const [isDrilldown, setIsDrilldown] = useState<boolean>(false);
	const [drilldownId, setDrilldownId] = useState<string | null>(null);

	const budgetTotals = selectBudgetTotals(props.budget, props.frequency);

	let data: Array<TransactionChartElement> = [];

	const categories = props.budget.categories;

	useEffect(() => {
		if (isDrilldown && !drilldownId) {
			setIsDrilldown(false);
			setDrilldownId(null);
		}

		if (
			isDrilldown &&
			drilldownId &&
			!budgetTotals.categoryTotals.has(drilldownId)
		) {
			setIsDrilldown(false);
			setDrilldownId(null);
		}
	}, [isDrilldown, drilldownId, budgetTotals.categoryTotals, categories]);

	let category: Category | null = null;

	if (isDrilldown && drilldownId) {
		category =
			props.budget.categories[
				findCategoryIndex(props.budget.categories, drilldownId)
			];

		data = computeDrilldownTransactionChart(
			props.transactionList,
			category,
			props.frequency
		);
	} else {
		data = computeTransactionChart(
			props.budget,
			budgetTotals,
			categories,
			props.transactionList
		);
	}

	return (
		<div className="transaction-chart-container">
			<div className="transaction-chart-headers-container">
				<h2 className="transaction-chart-title">
					Expenses {isDrilldown && category !== null && ` - ${category.name}`}
				</h2>
				{isDrilldown && (
					<Button
						kind="primary"
						onClick={() => {
							setIsDrilldown(false);
							setDrilldownId(null);
						}}
					>
						Back
					</Button>
				)}
			</div>
			<ResponsiveContainer width="100%" height={500}>
				<BarChart width={500} height={300} data={data}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					{/* <Legend /> */}
					<Bar
						dataKey="total"
						fill="#8884d8"
						onClick={(evt) => {
							if (isDrilldown || evt.id === data[0].id) {
								return;
							}
							setIsDrilldown(true);
							setDrilldownId(evt.id);
						}}
					/>
					<Bar
						dataKey="transactionTotal"
						fill="#82ca9d"
						onClick={(evt) => {
							if (isDrilldown || evt.id === data[0].id) {
								return;
							}
							setIsDrilldown(true);
							setDrilldownId(evt.id);
						}}
					/>
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

const mapStateToProps = (state: BudgetAppState, ownProps: OwnProps) => {
	/**
	 * Type asserted as we will enforce a transaction must be associated with
	 * a budget that is loaded into memory.
	 */
	const budget = findBudget(
		state.budgets,
		ownProps.transactionList.budgetId
	) as Budget;

	return {
		budget,
		categoryMap: selectBudgetItemDefinitions(budget),
	};
};

export default connect(mapStateToProps)(TransactionChart);
