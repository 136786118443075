import { ArrowLeft16, ChartCustom32 } from '@carbon/icons-react';
import { AnyAction } from '@reduxjs/toolkit';
import {
	Button,
	ClickableTile,
	ErrorBoundary,
	FileUploader,
	Loading,
	Tile,
} from 'carbon-components-react';
import { Dispatch, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { budgetApiInstance } from '../../api';
import templates from '../../BudgetTemplates/budgetTemplates';
import LoadFromDatabaseModal from '../../components/LoadFromDatabase/LoadFromDatabaseModal';
import { useBackend } from '../../config/backendDomains';
import { Budget, SavedBudgetAndTransactionList } from '../../redux/Model';
import { addBudget } from '../../redux/reducers/addBudget/action';
import { loadBudget } from '../../redux/reducers/loadBudget/action';
import { openGlobalModal } from '../../redux/reducers/openGlobalModal/action';
import { GlobalModalState } from '../../redux/storeModel';
import { BUDGET_PAGE } from '../../routes/routes';
import { createBudget } from '../../utils/BudgetUtils';

interface OwnProps {
	isMobileView: boolean;
	budgetLoaded: boolean;
}

export type CreateBudgetPageProps = OwnProps &
	ReturnType<typeof mapDispatchToProps>;

const CreateBudgetPage: React.FC<CreateBudgetPageProps> = (props) => {
	const [openLoadModal, setOpenLoadModal] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const history = useHistory();

	const loadBudgetFromDB = async (budgetId: string) => {
		setIsLoading(true);

		try {
			const budgetAndLists = (await budgetApiInstance.loadBudget(budgetId))
				.data;
			props.dispatchLoadBudget(budgetAndLists, true);
		} catch (e) {
			console.error(e);
		}

		setIsLoading(false);
		history.push(BUDGET_PAGE);
	};

	if (isLoading) {
		return <Loading />;
	}

	return (
		<ErrorBoundary>
			<article className="create-budget-wizard-container">
				{props.budgetLoaded && (
					<div className="budget-page__back-button-container">
						<Button
							className="budget-page__back-button"
							iconDescription="Back"
							hasIconOnly
							kind="ghost"
							renderIcon={ArrowLeft16}
							onClick={() => {
								history.push(BUDGET_PAGE);
							}}
						></Button>
					</div>
				)}
				<h1>Select Budget Template</h1>
				<div className="create-budget-wizard-templates-container">
					<ClickableTile
						className="budget-page__budget-select-tile"
						handleClick={() => {
							props.dispatchCreateBudget();
							history.push(BUDGET_PAGE);
						}}
					>
						<h2>Blank</h2>
						<p>A blank budget template.</p>
						<ChartCustom32 />
					</ClickableTile>

					{templates.budgetTemplates.map((template, index) => (
						<ClickableTile
							className="budget-page__budget-select-tile"
							key={`budget-template-${index}`}
							handleClick={() => {
								props.dispatchCreateBudget({ ...template });
								history.push(BUDGET_PAGE);
							}}
						>
							<h2>{templates.budgetLabels[index]}</h2>
							<p>{templates.budgetDescriptions[index]}</p>
							<ChartCustom32 />
						</ClickableTile>
					))}
				</div>
				<div className="create-budget-wizard-container">
					<h1>Load a Budget</h1>
					<div className="create-budget-wizard-templates-container">
						<Tile className="budget-page__budget-select-tile">
							<h2>From File</h2>
							<FileUploader
								accept={['application/json', '.json']}
								buttonKind="ghost"
								buttonLabel="Load Budget"
								iconDescription="Load budget from file."
								filenameStatus="edit"
								onChange={(evt) => {
									const files = evt.target.files;
									if (files !== null && files[0]) {
										const reader = new FileReader();
										const file = files[0];

										reader.readAsText(file);

										reader.onloadend = () => {
											const data = reader.result as string;

											const budgetAndLists = JSON.parse(
												data
											) as SavedBudgetAndTransactionList;

											props.dispatchLoadBudget(budgetAndLists);
											history.push(BUDGET_PAGE);
										};
									}
								}}
							/>
						</Tile>
						<Tile className="budget-page__budget-select-tile">
							<h2>From Database</h2>
							<Button
								kind="ghost"
								disabled={!useBackend}
								iconDescription="Load budget from the database."
								onClick={async () => {
									setOpenLoadModal(true);
								}}
							>
								Load Budget
							</Button>
							{!useBackend && <p>Coming soon!</p>}
						</Tile>
					</div>
				</div>
				<LoadFromDatabaseModal
					isOpen={openLoadModal}
					onRequestSubmit={async (budgetId: string) => {
						setOpenLoadModal(false);
						loadBudgetFromDB(budgetId);
					}}
					onRequestClose={() => {
						setOpenLoadModal(false);
					}}
				/>
			</article>
		</ErrorBoundary>
	);
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
	return {
		dispatchOpenGlobalModal: (config: GlobalModalState) => {
			dispatch(openGlobalModal(config));
		},
		dispatchCreateBudget: (budget?: Budget) => {
			if (budget === undefined) {
				dispatch(addBudget(createBudget()));
			} else {
				dispatch(addBudget(budget));
			}
		},
		dispatchLoadBudget: (
			budget: SavedBudgetAndTransactionList,
			fromDB?: boolean
		) => {
			dispatch(loadBudget(budget, fromDB));
		},
	};
};

export default connect(null, mapDispatchToProps)(CreateBudgetPage);
