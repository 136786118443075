import { DateTime } from 'luxon';

/**
 * Returns a list of years between the minDate and maxDate params.
 */
export const calculateMinMaxYearList = (
	minDate: DateTime,
	maxDate: DateTime
): Array<number> => {
	const output = [];
	for (let i = minDate.year; i <= maxDate.year; ++i) {
		output.push(i);
	}
	return output;
};

export const calculateMinMaxFiscalYearList = (
	minDate: DateTime,
	maxDate: DateTime
): Array<string> => {
	const output = [];
	for (let i = minDate.year; i <= maxDate.year; ++i) {
		output.push(`${i}/${i + 1}`);
	}
	return output;
};
