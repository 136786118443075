import React, { Dispatch, useEffect, useState } from 'react';
import './BudgetPage.scss';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import {
	Grid,
	Row,
	Column,
	Button,
	Select,
	SelectItem,
	TextInput,
} from 'carbon-components-react';
import { connect } from 'react-redux';
import { BudgetAppState, GlobalModalState } from '../../redux/storeModel';
import { AnyAction } from '@reduxjs/toolkit';
import { Budget, SavedBudgetAndTransactionList } from '../../redux/Model';
import { createBudget } from '../../utils/BudgetUtils';
import { addBudget } from '../../redux/reducers/addBudget/action';
import { loadBudget } from '../../redux/reducers/loadBudget/action';
import { openGlobalModal } from '../../redux/reducers/openGlobalModal/action';
import BudgetPageBody from './BudgetPageBody';
import { Add16, Edit24, Save24 } from '@carbon/icons-react';
import { useHistory } from 'react-router-dom';
import { CREATE_BUDGET_PAGE } from '../../routes/routes';
import { updateBudgetName } from '../../redux/reducers/updateBudgetName/action';

interface OwnProps {
	isMobile: boolean;
}

export type BudgetPageProps = OwnProps &
	ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;

const BudgetPage: React.FunctionComponent<BudgetPageProps> = (props) => {
	const budgetList = props.budgets;
	const history = useHistory();
	const [selectedBudgetId, setSelectedBudgetId] = useState<string | undefined>(
		budgetList[0]?.UUID
	);

	const selectedBudget: Budget | undefined = budgetList.find(
		(budget) => budget.UUID === selectedBudgetId
	);
	const [editName, setEditName] = useState<boolean>(false);
	const [newName, setNewName] = useState<string>(selectedBudget?.name || '');
	useEffect(() => {
		if (editName) {
			setNewName(selectedBudget?.name || '');
		}
	}, [editName, selectedBudget?.name]);

	useEffect(() => {
		if (budgetList.length === 0) {
			history.push(CREATE_BUDGET_PAGE);
		} else if (selectedBudget === undefined) {
			setSelectedBudgetId(budgetList[0].UUID);
		}
	}, [budgetList, history, selectedBudget]);

	return (
		<ErrorBoundary>
			<article>
				<Grid>
					<Row className="budget-page__banner">
						<Column lg={16}>
							<div className="budget-page__header-container">
								{editName && (
									<div className="budget-page__heading-edit-container">
										<TextInput
											className="budget-page__heading-edit"
											labelText=""
											id={selectedBudget?.UUID || 'Default ID'}
											value={newName}
											onChange={(e) => {
												if (!selectedBudget) {
													return;
												}
												setNewName(e.target.value);
											}}
										></TextInput>
										<Button
											hasIconOnly
											kind="ghost"
											iconDescription="Save name"
											renderIcon={Save24}
											onClick={() => {
												if (!selectedBudget) {
													return;
												}
												setEditName(!editName);
												props.dispatchUpdateBudgetName(
													newName,
													selectedBudget?.UUID
												);
											}}
										/>
									</div>
								)}
								{!editName && (
									<div className="budget-page__heading-edit-container">
										<h1 className="budget-page__heading">
											{selectedBudget?.name}
										</h1>
										<Button
											hasIconOnly
											kind="ghost"
											iconDescription="Edit name"
											renderIcon={Edit24}
											onClick={() => {
												setEditName(!editName);
											}}
										/>
									</div>
								)}
								<div className="budget-page__options">
									{budgetList.length > 0 && (
										<Select
											id="budget-page__budget-selector"
											labelText=""
											onChange={(e) => {
												setSelectedBudgetId(e.target.value);
											}}
										>
											{budgetList.map((budget) => (
												<SelectItem
													text={budget.name || ''}
													value={budget.UUID}
													key={budget.UUID}
												></SelectItem>
											))}
										</Select>
									)}
									<Button
										renderIcon={Add16}
										iconDescription="Add Budget"
										onClick={() => {
											history.push(CREATE_BUDGET_PAGE);
										}}
									>
										Add Budget
									</Button>
								</div>
							</div>
						</Column>
					</Row>
					<Row>
						<Column>
							<BudgetPageBody
								isMobileView={props.isMobile}
								budget={selectedBudget}
							></BudgetPageBody>
						</Column>
					</Row>
				</Grid>
			</article>
		</ErrorBoundary>
	);
};

const mapStateToProps = (state: BudgetAppState) => {
	return {
		budgets: state.budgets,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
	return {
		dispatchOpenGlobalModal: (config: GlobalModalState) => {
			dispatch(openGlobalModal(config));
		},
		dispatchCreateBudget: (budget?: Budget) => {
			if (budget === undefined) {
				dispatch(addBudget(createBudget()));
			} else {
				dispatch(addBudget(budget));
			}
		},
		dispatchLoadBudget: (
			budget: SavedBudgetAndTransactionList,
			fromDB?: boolean
		) => {
			dispatch(loadBudget(budget, fromDB));
		},
		dispatchUpdateBudgetName: (name: string, budgetId: string) => {
			dispatch(updateBudgetName(name, budgetId));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BudgetPage);
