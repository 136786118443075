
import { BudgetAppState } from '../../storeModel';
import { findBudgetIndex, findCategoryIndex } from '../reducerUtils';
import { DeleteBudgetCategoryAction } from './action';

export const reduceDeleteBudgetCategory = (
	prevState: BudgetAppState,
	action: DeleteBudgetCategoryAction,
): BudgetAppState => {

	const budgetIndex = findBudgetIndex(prevState.budgets, action.budgetId);

	const budget = prevState.budgets[budgetIndex];

	const newBudget = {...budget};

	if (!newBudget) {
		console.error(`Budget with ID ${action.budgetId} not found!`);
		return prevState;
	}

	const categoryIndex = findCategoryIndex(newBudget.categories, action.categoryId);

	newBudget.categories = [...newBudget.categories];
	newBudget.categories.splice(categoryIndex, 1);

	const newState = {...prevState};
	newState.budgets = [...newState.budgets];
	newState.budgets[budgetIndex] = newBudget;

	return newState;
}

