import { BudgetAppState } from '../../storeModel';
import { findBudgetIndex } from '../reducerUtils';
import { DeleteBudgetAction } from './action';

export const reduceDeleteBudget = (
	prevState: BudgetAppState,
	action: DeleteBudgetAction,
): BudgetAppState => {

	const budgetIndex = findBudgetIndex(prevState.budgets, action.budgetId);

	if(!prevState.budgets[budgetIndex]) {
		console.error(`Budget with ID ${action.budgetId} not found!`);
		return prevState;
	}

	const newBudgetList = [...prevState.budgets];
	newBudgetList.splice(budgetIndex, 1);

	const newTransactionLists = prevState.transactionLists.filter(list => (list.budgetId !== action.budgetId));

	const newState: BudgetAppState = {
		...prevState,
		budgets: newBudgetList,
		transactionLists: newTransactionLists,
	}

	return newState;
}


