import { Store } from '@reduxjs/toolkit';
import { createStore, StoreEnhancer } from 'redux';
import rootReducer from './rootReducer';
import {
	initialState,
	testState 
} from './state';
import { BudgetAppState } from './storeModel';

const configureStore = (): Store<BudgetAppState> => {
	let reduxDevtoolsExtension: StoreEnhancer | undefined;

	if (process.env.NODE_ENV !== 'production') {
		if (
			typeof window !== 'undefined' &&
			(window as any).__REDUX_DEVTOOLS_EXTENSION__
		) {
			reduxDevtoolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__();
		}
		return createStore(rootReducer, testState, reduxDevtoolsExtension);
	}

	return createStore(rootReducer, initialState, reduxDevtoolsExtension);
};

export default configureStore;
