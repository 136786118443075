import { BudgetAppState } from '../../redux/storeModel';
import { Modal } from 'carbon-components-react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { closeGlobalModal } from '../../redux/reducers/closeGlobalModal/action';

type GlobalModalProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;

const GlobalModal: React.FC<GlobalModalProps> = (props) => {
	const onRequestSubmit = (
		event:
			| React.MouseEvent<HTMLButtonElement>
			| React.KeyboardEvent<HTMLDivElement>
	) => {
		if (props.modalProps.onRequestSubmit) {
			props.modalProps.onRequestSubmit(event);
		}

		props.dispatchCloseGlobalModal();
	};

	const onRequestClose = (
		event:
			| React.MouseEvent<HTMLButtonElement>
			| React.KeyboardEvent<HTMLDivElement>
	) => {
		if (props.modalProps.onRequestClose) {
			props.modalProps.onRequestClose(event);
		}

		props.dispatchCloseGlobalModal();
	};

	const onSecondarySubmit = (
		event:
			| React.MouseEvent<HTMLButtonElement>
			| React.KeyboardEvent<HTMLDivElement>
	) => {
		if (props.modalProps.onSecondarySubmit) {
			props.modalProps.onSecondarySubmit(event);
		}

		props.dispatchCloseGlobalModal();
	};

	return (
		<Modal
			{...props.modalProps}
			onRequestSubmit={onRequestSubmit}
			onRequestClose={onRequestClose}
			onSecondarySubmit={onSecondarySubmit}
		>
			{props.modalChildren}
		</Modal>
	);
};

const mapStateToProps = (state: BudgetAppState) => {
	return state.globalModalState;
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
	return {
		dispatchCloseGlobalModal: () => {
			dispatch(closeGlobalModal());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalModal);
