import { BudgetAppState } from '../../storeModel';
import { AddBudgetAction } from './action';

const reduceAddBudget = (
	prevState: BudgetAppState,
	action: AddBudgetAction,
): BudgetAppState => {
	const newBudgetList = [...prevState.budgets];
	newBudgetList.push(action.budget);

	return {
		...prevState,
		budgets: newBudgetList
	};
}


export default reduceAddBudget;

