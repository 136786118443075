
import { BudgetAppState } from '../../storeModel';
import { OpenGlobalModalAction } from './action';

export const reduceOpenGlobalModal = (
	prevState: BudgetAppState,
	action: OpenGlobalModalAction
): BudgetAppState => {
	const newModalState = {...action.modalConfig}
	// So we don't have to set this every time we create the action
	newModalState.modalProps.open = true;

	return {...prevState,
		globalModalState: newModalState,
	};
}