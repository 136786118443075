import { BUDGETAPP_DELETE_BUDGET_ACTIONTYPE } from '../../actionTypes';

export interface DeleteBudgetAction {
	readonly type: typeof BUDGETAPP_DELETE_BUDGET_ACTIONTYPE;
	budgetId: string;
}

export const deleteBudget = (
	budgetId: string,
): DeleteBudgetAction => ({
	type: BUDGETAPP_DELETE_BUDGET_ACTIONTYPE,
	budgetId,
});

