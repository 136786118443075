import { Budget, BudgetItem } from '../Model';

const selectBudgetItemDefinitions = (
	budget: Budget
): Map<string, Array<BudgetItem>> => {
	const categoryItemMap = new Map<string, Array<BudgetItem>>();

	budget.categories.forEach((category) => {
		categoryItemMap.set(category.UUID, category.items);
	});

	return categoryItemMap;
};

export default selectBudgetItemDefinitions;
