import {
	BudgetList,
	SavedBudget,
	SavedBudgetAndTransactionList,
} from '../redux/Model';
import { objToCamel } from '../utils/apiUtils';
import ApiBase from './apiBase';

class BudgetApi extends ApiBase {
	route = '/budget';

	async listBudgets() {
		const res = await this.client.get<BudgetList>(`${this.route}/list`);
		res.data = objToCamel<BudgetList>(res.data);
		return res;
	}

	async loadBudget(budgetId: string) {
		const res = await this.client.get<SavedBudgetAndTransactionList>(
			`${this.route}/${budgetId}`
		);

		res.data = objToCamel<SavedBudgetAndTransactionList>(res.data);
		return res;
	}

	async saveBudget(budget: SavedBudget) {
		const res = await this.client.put<SavedBudgetAndTransactionList>(
			`${this.route}/${budget.UUID}`,
			{ budget }
		);

		res.data = objToCamel<SavedBudgetAndTransactionList>(res.data);
		return res;
	}
}

export default BudgetApi;
