import { DateTime } from 'luxon';
import { DateRange, TransactionList } from '../Model';

const selectMinMaxDates = (
	transactionList: TransactionList
): DateRange => {

	const transactionDates: Array<DateTime> = new Array<DateTime>();

	transactionList.transactions.forEach(transaction => {
		transactionDates.push(transaction.date);
	});

	return {
		min: DateTime.min.apply(this, transactionDates),
		max: DateTime.max.apply(this, transactionDates)
	}
}

export default selectMinMaxDates;
