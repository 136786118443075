import { AnyAction } from '@reduxjs/toolkit';
import {
	Checkbox,
	DataTableCell,
	DataTableHeader,
	Pagination,
	Select,
	SelectItem,
	TableContainer,
	TableToolbar,
	TableToolbarContent,
	TableToolbarSearch,
} from 'carbon-components-react';
import { Dispatch, ReactNode, useState } from 'react';
import { connect } from 'react-redux';
import {
	Budget,
	Category,
	PLACEHOLDER_CATEGORY,
	Transaction,
	TransactionList,
} from '../../redux/Model';
import { BudgetAppState } from '../../redux/storeModel';
import './TransactionTable.scss';
import { DateTime } from 'luxon';

import {
	DataTable,
	Table,
	TableHead,
	TableRow,
	TableHeader,
	TableBody,
	TableCell,
} from 'carbon-components-react';
import React from 'react';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { updateTransaction } from '../../redux/reducers/updateTransaction/action';
import { findBudget } from '../../redux/reducers/reducerUtils';
import selectBudgetItemDefinitions from '../../redux/selectors/selectBudgetItemDefinitions';
import {
	lookupBudgetCategory,
	lookupBudgetItem,
} from '../../utils/BudgetUtils';
import { useMemo } from 'react';

interface OwnProps {
	transactionList: TransactionList;
	transactionListIndex: number;
}

const headers = [
	{
		key: 'date',
		header: 'Date',
	},
	{
		key: 'amount',
		header: 'Amount',
	},
	{
		key: 'label',
		header: 'Label',
	},
	{
		key: 'categoryId',
		header: 'Budget Category',
	},
	{
		key: 'itemId',
		header: 'Associated Budget Item',
	},
	{
		key: 'isTaxDeductable',
		header: 'Tax Deductable',
	},
];

const CATEGORY_INDEX = headers.findIndex((header) => {
	return header.key === 'categoryId';
});
const TAX_DEDUCTABLE_INDEX = headers.findIndex((header) => {
	return header.key === 'isTaxDeductable';
});
const BUDGET_ITEM_INDEX = headers.findIndex((header) => {
	return header.key === 'itemId';
});

const AMOUNT_INDEX = headers.findIndex((header) => header.key === 'amount');

export type TransactionListProps = OwnProps &
	ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;

const DEFAULT_CATEGORY_LABEL = 'Uncategorised';
const DEFAULT_ITEM_LABEL = 'Choose an item';

/**
 * Renders a cell in the transaction table based on the column of the cell.
 * @param cell
 * @param cellIndex
 * @param budgetId
 * @param transactionIndex
 * @param transactionListIndex
 * @param transaction
 * @param previousCell
 * @param props
 * @returns
 */
const renderTransactionTableCell = (
	cell: DataTableCell,
	cellIndex: number,
	budgetId: string | null,
	transaction: Transaction,
	previousCell: DataTableCell<any, DataTableHeader<string>> | undefined,
	props: TransactionListProps
): ReactNode => {
	// Can sometimes happen due to weird rendering bug.
	if (!transaction || !cell) {
		return <TableCell>Something went wrong.</TableCell>;
	}

	if (cellIndex === TAX_DEDUCTABLE_INDEX) {
		return (
			<TableCell key={`${transaction.UUID}-${cell.id}`}>
				<Checkbox
					labelText="Tax Deductable"
					id={cell.id}
					checked={cell.value}
					value={cell.value ? cell.value : ''}
					hideLabel
					onChange={(evt: boolean) => {
						if (budgetId === null) {
							return;
						}

						props.dispatchUpdateTransaction(budgetId, {
							...transaction,
							isTaxDeductable: evt,
						});
					}}
				/>
			</TableCell>
		);
	}

	if (cellIndex === CATEGORY_INDEX) {
		const value: string =
			cell.value === undefined ? PLACEHOLDER_CATEGORY : cell.value;

		return (
			<TableCell key={`${transaction.UUID}-${cell.id}`}>
				<Select
					id={`${cell.id}-select`}
					value={value}
					labelText="Transaction Category"
					inline
					// helperText="Category of transaction to be matched to a budget category."
					hideLabel
					onChange={(event) => {
						props.dispatchUpdateTransaction(budgetId ? budgetId : '', {
							...transaction,
							categoryId: event.target.value,
						});
					}}
				>
					<SelectItem
						disabled={true}
						hidden={true}
						value={PLACEHOLDER_CATEGORY}
						text={DEFAULT_CATEGORY_LABEL}
					/>
					{props.categories.map((category) => (
						<SelectItem
							text={category.name}
							value={category.UUID}
							key={category.UUID}
						/>
					))}
				</Select>
			</TableCell>
		);
	}

	if (cellIndex === BUDGET_ITEM_INDEX) {
		const associatedCategory = props.categoryMap.get(previousCell?.value);
		const value: string =
			cell.value === undefined ? PLACEHOLDER_CATEGORY : cell.value;
		return (
			<TableCell key={`${transaction.UUID}-${cell.id}`}>
				<Select
					id={`${cell.id}-select`}
					value={value}
					labelText="Transaction Category"
					inline
					hideLabel
					onChange={(event) => {
						props.dispatchUpdateTransaction(budgetId ? budgetId : '', {
							...transaction,
							itemId: event.target.value,
						});
					}}
				>
					<SelectItem
						disabled={true}
						hidden={true}
						value={PLACEHOLDER_CATEGORY}
						text={DEFAULT_ITEM_LABEL}
					/>
					{associatedCategory !== undefined &&
						associatedCategory.map((item) => (
							<SelectItem text={item.name} value={item.UUID} key={item.UUID} />
						))}
				</Select>
			</TableCell>
		);
	}

	if (cellIndex === AMOUNT_INDEX) {
		return (
			<TableCell
				key={`${transaction.UUID}-${cell.id}`}
			>{`$ ${cell.value}`}</TableCell>
		);
	}

	if (cell.value === null) {
		return <TableCell key={`${transaction.UUID}-${cell.id}`}></TableCell>;
	}

	return (
		<TableCell key={`${transaction.UUID}-${cell.id}`}>
			{typeof cell.value === 'object'
				? (cell.value as DateTime).toLocaleString()
				: cell.value}
		</TableCell>
	);
};

export const TransactionTableComponent: React.FunctionComponent<
	TransactionListProps
> = (props) => {
	const transactionList = props.transactionList;

	// Pagination state
	const [currPageNum, setCurrPageNum] = useState<number>(1);
	const [currPageSize, setCurrPageSize] = useState<number>(10);

	const [searchTerm, setSearchTerm] = useState<string | null>(null);

	// Memoise final transactions to render for better performance
	let transactionsToRender = useMemo(() => {
		let transactionsToRender = transactionList.transactions;

		// Sort by date
		transactionsToRender.sort((a, b) => {
			return a.date.diff(b.date).milliseconds;
		});

		// Filter transaction list based on search term, if exists.
		if (searchTerm && searchTerm.length > 0) {
			transactionsToRender = transactionsToRender.filter((transaction) => {
				const searchTermStr = searchTerm ? searchTerm : '';

				if (!searchTermStr) {
					return false;
				}

				if (transaction.label.includes(searchTermStr)) {
					return true;
				}

				const transactionDateString = (
					transaction.date as DateTime
				).toLocaleString();

				if (transactionDateString.includes(searchTerm)) {
					return true;
				}

				const budgetCategory = transaction.categoryId
					? lookupBudgetCategory(props.categories, transaction.categoryId)
					: null;
				const budgetCategoryLabel = budgetCategory
					? budgetCategory.name
					: DEFAULT_CATEGORY_LABEL;

				if (budgetCategoryLabel.includes(searchTermStr)) {
					return true;
				}

				const budgetItems = transaction.categoryId
					? props.categoryMap.get(transaction.categoryId)
					: null;
				const budgetItem =
					budgetItems && transaction.itemId
						? lookupBudgetItem(budgetItems, transaction.itemId)
						: null;
				const budgetItemLabel = budgetItem
					? budgetItem.name
					: DEFAULT_ITEM_LABEL;
				if (budgetItemLabel.includes(searchTermStr)) {
					return true;
				}

				return false;
			});
		}

		// Paginate the transactions
		transactionsToRender = transactionsToRender.slice(
			(currPageNum - 1) * currPageSize,
			currPageNum * currPageSize
		);

		return transactionsToRender;
	}, [
		transactionList.transactions,
		searchTerm,
		currPageSize,
		currPageNum,
		props.categories,
		props.categoryMap,
	]);

	return (
		<ErrorBoundary dataToLog={transactionsToRender}>
			<DataTable
				// TODO investigate this and sort out ID properly
				rows={transactionsToRender.map((transaction) => ({
					...transaction,
					id: transaction.UUID,
				}))}
				headers={headers}
				render={({
					rows,
					headers,
					getTableProps,
					getHeaderProps,
					getRowProps,
				}) => (
					<TableContainer>
						<TableToolbar>
							<TableToolbarContent>
								<TableToolbarSearch
									expanded={true}
									contentEditable={true}
									onChange={(e) => {
										setSearchTerm(e.target.value);
									}}
									labelText="Search"
								/>
							</TableToolbarContent>
						</TableToolbar>
						<Table {...getTableProps()}>
							<TableHead>
								<TableRow>
									{headers.map((header) => (
										<TableHeader {...getHeaderProps({ header })}>
											{header.header}
										</TableHeader>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row, transactionIndex) => {
									return (
										<TableRow {...getRowProps({ row })}>
											{row.cells.map((cell, cellIndex) => {
												const previousCell = row.cells[cellIndex - 1];
												return renderTransactionTableCell(
													cell,
													cellIndex,
													transactionList.budgetId,
													transactionsToRender[transactionIndex],
													previousCell,
													props
												);
											})}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
						<Pagination
							page={currPageNum}
							itemsPerPageText="Items per page:"
							pageNumberText="Page number"
							pageSize={currPageSize}
							pageSizes={[10, 50, 100, 200]}
							totalItems={transactionList.transactions.length}
							onChange={(data) => {
								setCurrPageNum(data.page);
								setCurrPageSize(data.pageSize);
							}}
						/>
					</TableContainer>
				)}
			></DataTable>
		</ErrorBoundary>
	);
};

const mapStateToProps = (state: BudgetAppState, ownProps: OwnProps) => {
	let categories: Array<Category> | undefined;
	let budget: Budget;

	if (ownProps.transactionList.budgetId) {
		budget = findBudget(
			state.budgets,
			ownProps.transactionList.budgetId
		) as Budget;
		categories = budget?.categories;
	} else {
		throw new Error('Must have a budget ID');
	}

	if (!categories) {
		categories = [];
	}

	const categoryMap = selectBudgetItemDefinitions(budget);

	return {
		budget,
		categories,
		categoryMap,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
	return {
		dispatchUpdateTransaction: (budgetId: string, transaction: Transaction) => {
			dispatch(updateTransaction(budgetId, transaction));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TransactionTableComponent);
