import { BUDGETAPP_UPDATE_BUDGET_NAME } from '../../actionTypes';

export interface UpdateBudgetNameAction {
	readonly type: typeof BUDGETAPP_UPDATE_BUDGET_NAME;
	name: string;
	budgetId: string;
}

export const updateBudgetName = (
	name: string,
	budgetId: string
): UpdateBudgetNameAction => ({
	type: BUDGETAPP_UPDATE_BUDGET_NAME,
	name,
	budgetId,
});
