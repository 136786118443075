import { BUDGETAPP_UPDATE_BUDGET_ITEM_ACTIONTYPE } from "../../actionTypes";
import { BudgetItem } from "../../Model";


export interface UpdateBudgetItemAction {
	readonly type: typeof BUDGETAPP_UPDATE_BUDGET_ITEM_ACTIONTYPE;
	budgetId: string;
	categoryId: string;
	budgetItem: BudgetItem;
}

export const updateBudgetItem = (
	budgetId: string,
	categoryId: string, 
	budgetItem: BudgetItem
): UpdateBudgetItemAction => ({
	type: BUDGETAPP_UPDATE_BUDGET_ITEM_ACTIONTYPE,
	budgetId,
	categoryId,
	budgetItem,
});
