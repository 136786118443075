import './App.scss';
import TopBar from './components/TopBar/TopBar';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import LandingPage from './pages/Landing/LandingPage';
import BudgetPage from './pages/Budget/BudgetPage';
import { Content } from 'carbon-components-react';
import { useMediaQuery } from '@react-hook/media-query';
import TransactionPage from './pages/Transactions/TransactionPage';
import { BudgetAppState } from './redux/storeModel';
import { connect } from 'react-redux';
import GlobalDialog from './components/GlobalDialog/GlobalDialog';
import GlobalModal from './components/GlobalModal/GlobalModal';
import {
	BUDGET_PAGE,
	CREATE_BUDGET_PAGE,
	LANDING_PAGE,
	TRANSACTION_PAGE,
} from './routes/routes';
import CreateBudgetPage from './pages/CreateBudget/CreateBudgetPage';

type AppProps = ReturnType<typeof mapStateToProps>;

const App: React.FC<AppProps> = (props) => {
	const isMobileView = useMediaQuery('only screen and (max-width: 900px)');

	return (
		<div className="App">
			<GlobalDialog />
			<GlobalModal />
			<HashRouter>
				<TopBar />
				<Content className="root-content">
					<Switch>
						<Route exact path={LANDING_PAGE}>
							<LandingPage />
						</Route>
						<Route path={CREATE_BUDGET_PAGE}>
							<CreateBudgetPage
								budgetLoaded={props.numBudgets > 0}
								isMobileView={isMobileView}
							/>
						</Route>
						<Route path={BUDGET_PAGE}>
							<BudgetPage isMobile={isMobileView} />
						</Route>
						<Route path={TRANSACTION_PAGE}>
							{props.numBudgets === 0 ? (
								<Redirect to="/" />
							) : (
								<TransactionPage />
							)}
						</Route>
					</Switch>
					<p className="copyright">
						Copyright © 2022 Alex Borg All Rights Reserved.
					</p>
				</Content>
			</HashRouter>
		</div>
	);
};

const mapStateToProps = (state: BudgetAppState) => {
	return {
		numBudgets: state.budgets.length,
	};
};

export default connect(mapStateToProps)(App);
