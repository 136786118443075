
import { BUDGETAPP_OPEN_GLOBAL_DIALOG_ACTIONTYPE } from '../../actionTypes';
import { GlobalDialogState } from '../../storeModel';

export interface OpenGlobalDialogAction {
	readonly type: typeof BUDGETAPP_OPEN_GLOBAL_DIALOG_ACTIONTYPE;
	modalConfig: GlobalDialogState;
}

export const openGlobalDialog = (
	modalConfig: GlobalDialogState,
): OpenGlobalDialogAction => ({
	type: BUDGETAPP_OPEN_GLOBAL_DIALOG_ACTIONTYPE,
	modalConfig,
});
