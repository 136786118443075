import { AnyAction, Reducer } from '@reduxjs/toolkit';
import { BudgetAppState } from './storeModel';
import { initialState } from './state';
import reduceUpdateBudgetItem from './reducers/updateBudgetItem/reducer';
import * as ActionTypes from './actionTypes';
import { UpdateBudgetItemAction } from './reducers/updateBudgetItem/action';
import reduceAddBudgetCategory from './reducers/addBudgetCategory/reducer';
import { AddBudgetCategoryAction } from './reducers/addBudgetCategory/action';
import { UpdateBudgetCategoryAction } from './reducers/updateBudgetCategory/action';
import { reduceUpdateBudgetCategory } from './reducers/updateBudgetCategory/reducer';
import reduceAddBudgetItem from './reducers/addBudgetItem/reducer';
import { AddBudgetItemAction } from './reducers/addBudgetItem/action';
import { DeleteBudgetItemAction } from './reducers/deleteBudgetItem/action';
import reduceDeleteBudgetItem from './reducers/deleteBudgetItem/reducer';
import { reduceDeleteBudgetCategory } from './reducers/deleteBudgetCategory/reducer';
import { DeleteBudgetCategoryAction } from './reducers/deleteBudgetCategory/action';
import { reduceLoadBudget } from './reducers/loadBudget/reducer';
import { LoadBudgetAction } from './reducers/loadBudget/action';
import { DeleteBudgetAction } from './reducers/deleteBudget/action';
import { reduceDeleteBudget } from './reducers/deleteBudget/reducer';
import reduceAddBudget from './reducers/addBudget/reducer';
import { AddBudgetAction } from './reducers/addBudget/action';
import reduceAddTransactionList from './reducers/transactionList/reducer';
import { AddTransactionListAction } from './reducers/transactionList/action';
import reduceUpdateTransaction from './reducers/updateTransaction/reducer';
import { UpdateTransactionAction } from './reducers/updateTransaction/action';
import { reduceDeleteTransactionList } from './reducers/deleteTransactionList/reducer';
import { DeleteTransactionListAction } from './reducers/deleteTransactionList/action';
import { reduceOpenGlobalDialog } from './reducers/openGlobalDialog/reducer';
import { OpenGlobalDialogAction } from './reducers/openGlobalDialog/action';
import { CloseGlobalDialogAction } from './reducers/closeGlobalDialog/action';
import { reduceCloseGlobalDialog } from './reducers/closeGlobalDialog/reducer';
import { OpenGlobalModalAction } from './reducers/openGlobalModal/action';
import { CloseGlobalModalAction } from './reducers/closeGlobalModal/action';
import { reduceCloseGlobalModal } from './reducers/closeGlobalModal/reducer';
import { reduceOpenGlobalModal } from './reducers/openGlobalModal/reducer';
import reduceUpdateBudgetName from './reducers/updateBudgetName/reducer';
import { UpdateBudgetNameAction } from './reducers/updateBudgetName/action';

const rootReducer: Reducer<BudgetAppState, AnyAction> = (
	prevState: BudgetAppState = initialState,
	action
): BudgetAppState => {
	switch (action.type) {
		case ActionTypes.BUDGETAPP_UPDATE_BUDGET_ITEM_ACTIONTYPE: {
			return reduceUpdateBudgetItem(
				prevState,
				action as UpdateBudgetItemAction
			);
		}

		case ActionTypes.BUDGETAPP_ADD_BUDGET_CATEGORY_ACTIONTYPE: {
			return reduceAddBudgetCategory(
				prevState,
				action as AddBudgetCategoryAction
			);
		}

		case ActionTypes.BUDGETAPP_UPDATE_BUDGET_CATEGORY_ACTIONTYPE: {
			return reduceUpdateBudgetCategory(
				prevState,
				action as UpdateBudgetCategoryAction
			);
		}

		case ActionTypes.BUDGETAPP_ADD_BUDGET_ITEM_ACTIONTYPE: {
			return reduceAddBudgetItem(prevState, action as AddBudgetItemAction);
		}

		case ActionTypes.BUDGETAPP_DELETE_BUDGET_ITEM_ACTIONTYPE: {
			return reduceDeleteBudgetItem(
				prevState,
				action as DeleteBudgetItemAction
			);
		}

		case ActionTypes.BUDGETAPP_DELETE_BUDGET_CATEGORY_ACTIONTYPE: {
			return reduceDeleteBudgetCategory(
				prevState,
				action as DeleteBudgetCategoryAction
			);
		}

		case ActionTypes.BUDGETAPP_LOAD_BUDGET_ACTIONTYPE: {
			return reduceLoadBudget(prevState, action as LoadBudgetAction);
		}

		case ActionTypes.BUDGETAPP_DELETE_BUDGET_ACTIONTYPE: {
			return reduceDeleteBudget(prevState, action as DeleteBudgetAction);
		}

		case ActionTypes.BUDGETAPP_ADD_BUDGET_ACTIONTYPE: {
			return reduceAddBudget(prevState, action as AddBudgetAction);
		}

		case ActionTypes.BUDGETAPP_ADD_TRANSACTION_LIST_ACTIONTYPE: {
			return reduceAddTransactionList(
				prevState,
				action as AddTransactionListAction
			);
		}

		case ActionTypes.BUDGETAPP_UPDATE_TRANSACTION_ACTIONTYPE: {
			return reduceUpdateTransaction(
				prevState,
				action as UpdateTransactionAction
			);
		}

		case ActionTypes.BUDGETAPP_DELETE_TRANSACTION_LIST: {
			return reduceDeleteTransactionList(
				prevState,
				action as DeleteTransactionListAction
			);
		}

		case ActionTypes.BUDGETAPP_OPEN_GLOBAL_DIALOG_ACTIONTYPE: {
			return reduceOpenGlobalDialog(
				prevState,
				action as OpenGlobalDialogAction
			);
		}

		case ActionTypes.BUDGETAPP_CLOSE_GLOBAL_DIALOG_ACTIONTYPE: {
			return reduceCloseGlobalDialog(
				prevState,
				action as CloseGlobalDialogAction
			);
		}

		case ActionTypes.BUDGETAPP_OPEN_GLOBAL_MODAL_ACTIONTYPE: {
			return reduceOpenGlobalModal(prevState, action as OpenGlobalModalAction);
		}

		case ActionTypes.BUDGETAPP_CLOSE_GLOBAL_MODAL_ACTIONTYPE: {
			return reduceCloseGlobalModal(
				prevState,
				action as CloseGlobalModalAction
			);
		}

		case ActionTypes.BUDGETAPP_UPDATE_BUDGET_NAME: {
			return reduceUpdateBudgetName(
				prevState,
				action as UpdateBudgetNameAction
			);
		}
	}

	return prevState;
};

export default rootReducer;
