import { BudgetAppState } from '../../redux/storeModel';
import { Modal } from 'carbon-components-react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { closeGlobalDialog } from '../../redux/reducers/closeGlobalDialog/action';

type GlobalDialogProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;

const GlobalDialog: React.FC<GlobalDialogProps> = (props) => {
	return (
		<Modal
			danger={props.isDanger}
			modalHeading={props.title}
			open={props.isOpen}
			primaryButtonText={props.primaryButtonText}
			secondaryButtonText={props.secondaryButtonText}
			onRequestSubmit={props.onSubmit ? () => {
				// undefined check not necessary, but typescript...
				if(props.onSubmit !== undefined) {
					props.onSubmit();
				}
				props.dispatchCloseGlobalDialog();
			} : props.dispatchCloseGlobalDialog}
			onSecondarySubmit={
				props.onCancel ? () => {
					// undefined check not necessary, but typescript...
					if(props.onCancel !== undefined) {
						props.onCancel();
					}
					props.dispatchCloseGlobalDialog();
				} : props.dispatchCloseGlobalDialog
			}
			onRequestClose={
				props.onClose ? props.onClose : props.dispatchCloseGlobalDialog
			}
		>
			<div className="global-modal_contents">{props.text}</div>
		</Modal>
	);
};

const mapStateToProps = (state: BudgetAppState) => {
	return state.globalDialogState;
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
	return {
		dispatchCloseGlobalDialog: () => {
			dispatch(closeGlobalDialog());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalDialog);
