import { BUDGETAPP_LOAD_BUDGET_ACTIONTYPE } from '../../actionTypes';
import { SavedBudgetAndTransactionList } from '../../Model';

export interface LoadBudgetAction {
	readonly type: typeof BUDGETAPP_LOAD_BUDGET_ACTIONTYPE;
	budget: SavedBudgetAndTransactionList;
	fromDB?: boolean;
}

export const loadBudget = (
	budget: SavedBudgetAndTransactionList,
	fromDB?: boolean
): LoadBudgetAction => ({
	type: BUDGETAPP_LOAD_BUDGET_ACTIONTYPE,
	budget,
	fromDB,
});
