import { BUDGETAPP_ADD_BUDGET_ITEM_ACTIONTYPE } from '../../actionTypes';

export interface AddBudgetItemAction {
	readonly type: typeof BUDGETAPP_ADD_BUDGET_ITEM_ACTIONTYPE;
	budgetId: string;
	categoryId: string;
}

export const addBudgetItem = (
	budgetId: string,
	categoryId: string,
): AddBudgetItemAction => ({
	type: BUDGETAPP_ADD_BUDGET_ITEM_ACTIONTYPE,
	budgetId,
	categoryId,
});
