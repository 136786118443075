export const BUDGETAPP_UPDATE_BUDGET_ITEM_ACTIONTYPE =
	'budgetapp/update-budget-item';
export const BUDGETAPP_ADD_BUDGET_CATEGORY_ACTIONTYPE =
	'budgetapp/add-budget-category';
export const BUDGETAPP_UPDATE_BUDGET_CATEGORY_ACTIONTYPE =
	'budgetapp/update-budget-category';
export const BUDGETAPP_ADD_BUDGET_ITEM_ACTIONTYPE = 'budgetapp/add-budget-item';
export const BUDGETAPP_DELETE_BUDGET_ITEM_ACTIONTYPE =
	'budgetapp/delete-budget-item';
export const BUDGETAPP_DELETE_BUDGET_CATEGORY_ACTIONTYPE =
	'budgetapp/delete-budget-category';
export const BUDGETAPP_LOAD_BUDGET_ACTIONTYPE = 'budgetapp/load-budget';
export const BUDGETAPP_DELETE_BUDGET_ACTIONTYPE = 'budgetapp/delete-budget';
export const BUDGETAPP_ADD_BUDGET_ACTIONTYPE = 'budgetapp/add-budget';
export const BUDGETAPP_ADD_TRANSACTION_LIST_ACTIONTYPE =
	'budgetapp/add-budget-list';
export const BUDGETAPP_UPDATE_TRANSACTION_ACTIONTYPE =
	'budgetapp/update-transaction';
export const BUDGETAPP_DELETE_TRANSACTION_LIST =
	'budgetapp/delete-trasnaction-list';
export const BUDGETAPP_OPEN_GLOBAL_DIALOG_ACTIONTYPE =
	'bugetapp/open-global-dialog';
export const BUDGETAPP_CLOSE_GLOBAL_DIALOG_ACTIONTYPE =
	'bugetapp/close-global-dialog';
export const BUDGETAPP_OPEN_GLOBAL_MODAL_ACTIONTYPE =
	'bugetapp/open-global-modal';
export const BUDGETAPP_CLOSE_GLOBAL_MODAL_ACTIONTYPE =
	'bugetapp/close-global-modal';
export const BUDGETAPP_UPDATE_BUDGET_NAME = 'budgetapp/update-budget-name';
