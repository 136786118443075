
import { BudgetAppState } from '../../storeModel';
import { OpenGlobalDialogAction } from './action';

export const reduceOpenGlobalDialog = (
	prevState: BudgetAppState,
	action: OpenGlobalDialogAction
): BudgetAppState => {
	const newDialogState = {...action.modalConfig}

	return {...prevState,
		globalDialogState: newDialogState,
	};
}