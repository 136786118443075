import { BudgetAppState, GlobalDialogState, GlobalModalState } from './storeModel';
import { dummyBudget } from '../utils/dummy-budget';
import { dummyTransactionList } from '../utils/dummyTransactions';

export const globalDialogInitialState: GlobalDialogState = {
	isOpen: false,
}

export const globalModalInitialState: GlobalModalState = {
	modalProps: {},
}

export const initialState: BudgetAppState = {
	budgets: [],
	transactionLists: [],
	globalDialogState: globalDialogInitialState,
	globalModalState: globalModalInitialState
}

export const testState: BudgetAppState = {
	budgets: [{...dummyBudget}],
	transactionLists: [
		{...dummyTransactionList}
	],
	globalDialogState: globalDialogInitialState,
	globalModalState: globalModalInitialState
};
