import { BUDGETAPP_ADD_TRANSACTION_LIST_ACTIONTYPE } from "../../actionTypes";
import { TransactionList } from "../../Model";

export interface AddTransactionListAction {
	readonly type: typeof BUDGETAPP_ADD_TRANSACTION_LIST_ACTIONTYPE;
	transactionList: TransactionList;
	budgetId: string;
}

export const addTransactionList = (
	transactionList: TransactionList,
	budgetId: string,
): AddTransactionListAction => ({
	type: BUDGETAPP_ADD_TRANSACTION_LIST_ACTIONTYPE,
	transactionList,
	budgetId,
});
