import { BUDGETAPP_ADD_BUDGET_ACTIONTYPE } from '../../actionTypes';
import { Budget } from '../../Model';


export interface AddBudgetAction {
	readonly type: typeof BUDGETAPP_ADD_BUDGET_ACTIONTYPE;
	budget: Budget;
}

export const addBudget = (
	budget: Budget,
): AddBudgetAction => ({
	type: BUDGETAPP_ADD_BUDGET_ACTIONTYPE,
	budget,
});

