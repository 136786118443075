
import { BUDGETAPP_OPEN_GLOBAL_MODAL_ACTIONTYPE } from '../../actionTypes';
import { GlobalModalState } from '../../storeModel';

export interface OpenGlobalModalAction {
	readonly type: typeof BUDGETAPP_OPEN_GLOBAL_MODAL_ACTIONTYPE;
	modalConfig: GlobalModalState;
}

export const openGlobalModal = (
	modalConfig: GlobalModalState,
): OpenGlobalModalAction => ({
	type: BUDGETAPP_OPEN_GLOBAL_MODAL_ACTIONTYPE,
	modalConfig,
});
