import axios from 'axios';
import budgetApi from './budgetApi';

// TODO remove the port hard coding and configure some other way.
const hostname = window.location.hostname;
const protocol = window.location.protocol;

const localPort = hostname === 'localhost' ? ':3002' : '';

const baseURL = `${protocol}//${hostname}${localPort}/api/v1`;

const client = axios.create({
	baseURL,
});

export const budgetApiInstance = new budgetApi(client);
