import { BUDGETAPP_UPDATE_TRANSACTION_ACTIONTYPE } from "../../actionTypes";
import { Transaction } from "../../Model";

export interface UpdateTransactionAction {
	readonly type: typeof BUDGETAPP_UPDATE_TRANSACTION_ACTIONTYPE;
	budgetId: string;
	transaction: Transaction;
}

export const updateTransaction = (
	budgetId: string,
	transaction: Transaction,
): UpdateTransactionAction => ({
	type: BUDGETAPP_UPDATE_TRANSACTION_ACTIONTYPE,
	budgetId,
	transaction,
});

