import './TransactionPage.scss';
import React, { ReactNode, useState } from 'react';

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import {
	Accordion,
	AccordionItem,
	Button,
	Column,
	DatePicker,
	DatePickerInput,
	Grid,
	Row,
	Select,
	SelectItem,
	Tile,
} from 'carbon-components-react';
import TransactionTableComponent from '../../components/TransactionTable/TransactionTable';
import { BudgetAppState } from '../../redux/storeModel';
import { connect } from 'react-redux';
import {
	FrequencyOptions,
	TransactionList,
	DateRange,
	Months,
} from '../../redux/Model';
import { addTransactionList } from '../../redux/reducers/transactionList/action';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import TransactionChart from '../../components/TransactionChart/TransactionChart';
import { deleteTransactionList } from '../../redux/reducers/deleteTransactionList/action';
import { AddAlt24, TrashCan24 } from '@carbon/icons-react';
import ImportTransactions from '../../components/ImportTransactionsModal/ImportTransactions';
import selectTransactionTotals from '../../redux/selectors/selectTransactionTotals';
import { numberWithCommas } from '../../utils/numberUtils';
import { selectFrequencySuffix } from '../../utils/BudgetUtils';
import selectMinMaxDates from '../../redux/selectors/selectMinMaxDates';
import { DateTime } from 'luxon';
import { openGlobalDialog } from '../../redux/reducers/openGlobalDialog/action';
import {
	calculateMinMaxFiscalYearList,
	calculateMinMaxYearList,
} from '../../utils/DateUtils';

interface OwnProps {}

interface TransactionPageTransactionList extends TransactionList {
	minMaxDates: {
		min: DateTime;
		max: DateTime;
	};
}

type TransactionPageProps = OwnProps &
	ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;

// TODO refactor each accordion into its own component with state.
const TransactionPage: React.FC<TransactionPageProps> = (props) => {
	const [showTransactionModal, setShowTransactionModal] =
		useState<boolean>(false);
	const [currBudgetId, setCurrBudgetId] = useState<string | null>(null);

	const [frequency, setFrequency] = useState<FrequencyOptions>(
		FrequencyOptions.Annually
	);

	const [selectedMonth, setSelectedMonth] = useState<number>(
		props.transactionLists[0]?.minMaxDates.min.month || 1
	);
	const [selectedYear, setSelectedYear] = useState<number>(
		props.transactionLists[0]?.minMaxDates.min.year || 2020
	);
	const [selectedFiscalYear, setSelectedFiscalYear] = useState<string>(
		props.transactionLists[0]
			? `${props.transactionLists[0].minMaxDates.min.year}/${
					props.transactionLists[0].minMaxDates.min.year + 1
			  }`
			: '2020/2021'
	);

	const [selectedMonthRange, setSelectedMonthRange] =
		useState<DateRange | null>({
			min: DateTime.fromMillis(
				Date.UTC(Number(selectedFiscalYear.split('/')[0]), 7, 1)
			),
			max: DateTime.fromMillis(
				Date.UTC(Number(selectedFiscalYear.split('/')[1]), 6, 30)
			),
		});

	const dateRangeSet: boolean =
		(selectedMonthRange !== null &&
			selectedMonthRange.min !== undefined &&
			selectedMonthRange.max !== undefined) ||
		frequency === FrequencyOptions.Monthly ||
		frequency === FrequencyOptions.Annually ||
		frequency === FrequencyOptions.FinancialYear;

	const renderFrequency = (minDate: DateTime, maxDate: DateTime) => {
		return (
			<div className="transaction-page__frequency-container">
				<div>
					<Select
						id="transaction-frequency-input"
						value={frequency}
						labelText=""
						onChange={(event) => {
							// Assert type as we guarantee it is a possible value in the enum below.
							setFrequency(event.target.value as FrequencyOptions);
						}}
					>
						{/* TODO Uncomment this and remove subsequence lines
							Once weekly and fortnightly views are supported.
						*/}
						{/* {Object.values(FrequencyOptions).map((val) => {
							return (
								<SelectItem text={val} value={val} key={val}></SelectItem>
							);
						})} */}
						<SelectItem
							text={FrequencyOptions.FinancialYear}
							value={FrequencyOptions.FinancialYear}
							key={FrequencyOptions.FinancialYear}
						></SelectItem>
						<SelectItem
							text={FrequencyOptions.Annually}
							value={FrequencyOptions.Annually}
							key={FrequencyOptions.Annually}
						></SelectItem>
						<SelectItem
							text={FrequencyOptions.Monthly}
							value={FrequencyOptions.Monthly}
							key={FrequencyOptions.Monthly}
						></SelectItem>
						<SelectItem
							text={FrequencyOptions.Custom}
							value={FrequencyOptions.Custom}
							key={FrequencyOptions.Custom}
						></SelectItem>
						<SelectItem text={'All'} value={'All'} key={'All'}></SelectItem>
					</Select>
				</div>
				{frequency === FrequencyOptions.FinancialYear && (
					<div className="transaction-page__monthly-container">
						<Select
							id={`transaction-year-input`}
							value={selectedFiscalYear}
							labelText={'Year'}
							onChange={(event) => {
								setSelectedFiscalYear(event.target.value);
								// Type guranteed by below utility function
								const val = (event.target.value as string).split('/');
								setSelectedMonthRange({
									min: DateTime.fromMillis(Date.UTC(Number(val[0]), 7, 1)),
									max: DateTime.fromMillis(Date.UTC(Number(val[1]), 6, 30)),
								});
							}}
						>
							{calculateMinMaxFiscalYearList(minDate, maxDate).map((year) => (
								<SelectItem text={year} value={year} key={year}></SelectItem>
							))}
						</Select>
					</div>
				)}
				{frequency === FrequencyOptions.Annually && (
					<div className="transaction-page__monthly-container">
						<Select
							id={`transaction-year-input`}
							value={selectedYear}
							labelText={'Year'}
							onChange={(event) => {
								// Assert type as we guarantee it is a possible value in the enum below.
								setSelectedYear(Number(event.target.value));
							}}
						>
							{calculateMinMaxYearList(minDate, maxDate).map((year) => (
								<SelectItem
									text={year.toString()}
									value={year}
									key={year}
								></SelectItem>
							))}
						</Select>
					</div>
				)}
				{frequency === FrequencyOptions.Monthly && (
					<div className="transaction-page__monthly-container">
						<Select
							id={`transaction-month-input`}
							value={selectedMonth}
							labelText="Month"
							onChange={(event) => {
								// Assert type as we guarantee it is a possible value in the enum below.
								setSelectedMonth(Number(event.target.value));
							}}
						>
							{Object.entries(Months).map(([key, value]) => (
								<SelectItem text={key} value={value} key={key}></SelectItem>
							))}
						</Select>
						<Select
							id={`transaction-year-input`}
							value={selectedYear}
							labelText={'Year'}
							onChange={(event) => {
								// Assert type as we guarantee it is a possible value in the enum below.
								setSelectedYear(Number(event.target.value));
							}}
						>
							{calculateMinMaxYearList(minDate, maxDate).map((year) => (
								<SelectItem
									text={year.toString()}
									value={year}
									key={year}
								></SelectItem>
							))}
						</Select>
					</div>
				)}
				{frequency === FrequencyOptions.Custom && (
					<div>
						<DatePicker
							datePickerType="range"
							// TODO make this more flexible for different locales
							minDate={minDate.toFormat('dd/MM/yyyy')}
							maxDate={maxDate.toFormat('dd/MM/yyyy')}
							dateFormat="d/m/Y"
							onChange={(val) => {
								setSelectedMonthRange({
									min: DateTime.fromJSDate(val[0]),
									// Ensure that the max value is valid when user has only entered the min value so far.
									max: val[1]
										? DateTime.fromJSDate(val[1])
										: DateTime.fromISO(maxDate.toISO()),
								});
							}}
						>
							<DatePickerInput
								id="custom-range-picker-start"
								placeholder="dd/mm/yyyy"
								labelText="Start date"
							/>
							<DatePickerInput
								id="custom-range-picker-end"
								placeholder="dd/mm/yyyy"
								labelText="End date"
							/>
						</DatePicker>
					</div>
				)}
			</div>
		);
	};

	const renderTransactionList = (): ReactNode => {
		if (props.transactionLists.length === 0) {
			return (
				<Row>
					<Column lg={16}>
						<div className="transaction-list__load-container">
							<Tile className="budget-template-tile">
								<h2>Load Transactions</h2>
								<p>Load a list of transactions from a csv file</p>
								<Button
									text=""
									kind="ghost"
									onClick={() => {
										setCurrBudgetId(null);
										setShowTransactionModal(true);
									}}
								>
									Load Transaction List
								</Button>
							</Tile>
						</div>
					</Column>
				</Row>
			);
		} else {
			return (
				<>
					{props.transactionLists.map((transactionList, index) => {
						const categories = props.budgets.find(
							(budget) => budget.UUID === transactionList.budgetId
						)?.categories;

						const { min, max } = transactionList.minMaxDates;

						const filteredTransactionList = { ...transactionList };

						if (dateRangeSet) {
							let minDate = min;
							let maxDate = max;

							if (
								(frequency === FrequencyOptions.Custom &&
									selectedMonthRange !== null) ||
								(frequency === FrequencyOptions.FinancialYear &&
									selectedMonthRange !== null)
							) {
								minDate = selectedMonthRange.min;
								maxDate = selectedMonthRange.max;
							} else if (frequency === FrequencyOptions.Annually) {
								minDate = DateTime.fromJSDate(
									new Date(Date.UTC(selectedYear, 1 - 1))
								);
								maxDate = DateTime.fromJSDate(
									new Date(Date.UTC(selectedYear, 12 - 1, minDate.daysInMonth))
								);
							} else if (frequency === FrequencyOptions.Monthly) {
								minDate = DateTime.fromJSDate(
									new Date(Date.UTC(selectedYear, selectedMonth - 1))
								);
								maxDate = DateTime.fromJSDate(
									new Date(
										Date.UTC(
											selectedYear,
											selectedMonth - 1,
											minDate.daysInMonth
										)
									)
								);
							}

							filteredTransactionList.transactions =
								filteredTransactionList.transactions.filter(
									(transaction) =>
										DateTime.min(minDate, transaction.date).equals(minDate) &&
										DateTime.max(maxDate, transaction.date).equals(maxDate)
								);
						}

						const transactionTotals = selectTransactionTotals(
							filteredTransactionList,
							// custom frequency is not supported for calculations at present.
							frequency === FrequencyOptions.Custom ||
								frequency === FrequencyOptions.All ||
								frequency === FrequencyOptions.FinancialYear
								? FrequencyOptions.Annually
								: frequency,
							categories
						);

						return (
							<div
								className="transaction-page__transaction-list-container"
								key={`transaction-list-${index}`}
							>
								<Row>
									<Column lg={16}>
										<Accordion>
											<div className="transaction-page__transaction-accordion-container">
												<div className="transaction-page__transaction-accordion-item">
													<AccordionItem open={true} title="Transaction List">
														{renderFrequency(min, max)}
														<TransactionTableComponent
															transactionList={filteredTransactionList}
															transactionListIndex={index}
														/>
													</AccordionItem>
												</div>
												<div className="transaction-page__transaction-accordion-item-btns">
													<Button
														hasIconOnly
														renderIcon={AddAlt24}
														iconDescription="Import Transactions"
														kind="ghost"
														tooltipPosition="top"
														onClick={() => {
															setShowTransactionModal(true);
														}}
													/>
													<Button
														hasIconOnly
														renderIcon={TrashCan24}
														iconDescription="Clear Transactions"
														kind="ghost"
														tooltipPosition="top"
														onClick={() => {
															props.dispatchOpenGlobalDialog(() => {
																props.dispatchDeleteTransactionList(
																	transactionList.transactionListId
																);
															});
														}}
													/>
												</div>
											</div>
										</Accordion>
									</Column>
								</Row>
								<Row>
									<Column lg={16}>
										<div className="budget-totals-container">
											<p>
												Income Total: ${' '}
												{numberWithCommas(transactionTotals.incomeTotal, 2)}{' '}
												{selectFrequencySuffix(frequency)}
											</p>
											<p>
												Savings Total: $
												{numberWithCommas(transactionTotals.savingsTotal, 2)}{' '}
												{selectFrequencySuffix(frequency)}
											</p>
											<p>
												Expense Total: ${' '}
												{numberWithCommas(transactionTotals.expenseTotal, 2)}{' '}
												{selectFrequencySuffix(frequency)}
											</p>
											<p>
												Balance ${' '}
												{numberWithCommas(transactionTotals.balance, 2)}{' '}
												{selectFrequencySuffix(frequency)}
											</p>
										</div>
									</Column>
								</Row>
								<Row>
									<Column lg={16}>
										<TransactionChart
											transactionList={filteredTransactionList}
											totals={transactionTotals}
											frequency={
												// custom frequency is not supported for calculations at present.
												frequency === FrequencyOptions.Custom ||
												frequency === FrequencyOptions.All
													? FrequencyOptions.Annually
													: frequency
											}
										/>
									</Column>
								</Row>
							</div>
						);
					})}
				</>
			);
		}
	};

	return (
		<ErrorBoundary>
			<article>
				<Grid>
					<ImportTransactions
						open={showTransactionModal}
						onRequestSubmit={(transactionList: TransactionList) => {
							if (currBudgetId) {
								props.dispatchLoadTransactionList(
									transactionList,
									currBudgetId
								);
							} else {
								props.dispatchLoadTransactionList(
									transactionList,
									props.budgets[0].UUID
								);
							}

							setShowTransactionModal(false);
							setCurrBudgetId(null);
						}}
						onRequestClose={() => {
							setShowTransactionModal(false);
							setCurrBudgetId(null);
						}}
						budgets={props.budgets}
					/>
					<Row className="transaction-page__banner">
						<Column lg={16}>
							<h1 className="transaction-page__heading">Transactions</h1>
						</Column>
					</Row>
					{renderTransactionList()}
				</Grid>
			</article>
		</ErrorBoundary>
	);
};

const mapStateToProps = (state: BudgetAppState) => {
	let transactionLists = [...state.transactionLists];

	// Inject min and max dates
	transactionLists = transactionLists.map((transactionList) => {
		return {
			...transactionList,
			minMaxDates: selectMinMaxDates(transactionList),
		};
	});
	return {
		budgets: state.budgets,
		transactionLists: transactionLists as Array<TransactionPageTransactionList>,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
	return {
		dispatchLoadTransactionList: (
			transactionList: TransactionList,
			budgetId: string
		) => {
			dispatch(addTransactionList(transactionList, budgetId));
		},
		dispatchDeleteTransactionList: (transactionListId: string) => {
			dispatch(deleteTransactionList(transactionListId));
		},
		dispatchOpenGlobalDialog: (onSubmit: () => void) => {
			dispatch(
				openGlobalDialog({
					isOpen: true,
					title: 'Are you sure you want to delete your transactions?',
					text: 'Warning: Deletion is permanent',
					primaryButtonText: 'Delete',
					secondaryButtonText: 'Cancel',
					onSubmit,
				})
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionPage);
