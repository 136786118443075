import { BudgetAppState } from '../../storeModel';
import { UpdateTransactionAction } from './action';

/**
 * Updates a given transaction with the contents of the action.
 * @param prevState
 * @param action
 * @returns
 */
const reduceUpdateTransaction = (
	prevState: BudgetAppState,
	action: UpdateTransactionAction
): BudgetAppState => {
	// Find the index of the transaction list.
	const transactionListIndex = prevState.transactionLists.findIndex((list) => {
		return list.budgetId === action.budgetId;
	});

	if (transactionListIndex === -1) {
		throw new Error('Unable to find transaction list!');
	}

	// Dereference the transaction list.
	const newTransactionList = {
		...prevState.transactionLists[transactionListIndex],
	};

	// Find the transaction index.
	const transactionIndex = newTransactionList.transactions.findIndex(
		(trans) => {
			return trans.UUID === action.transaction.UUID;
		}
	);

	if (transactionIndex === -1) {
		throw new Error('Unable to find transaction!');
	}

	// Apply the new transaction list
	newTransactionList.transactions = [...newTransactionList.transactions];

	// Apply the transaction update.
	newTransactionList.transactions[transactionIndex] = { ...action.transaction };

	// Create a transaction lists container and apply it.
	const newTransactionLists = [...prevState.transactionLists];
	newTransactionLists[transactionListIndex] = newTransactionList;

	// Return the new application state.
	return {
		...prevState,
		transactionLists: newTransactionLists,
	};
};

export default reduceUpdateTransaction;
