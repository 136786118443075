import type { DateTime } from 'luxon';

export type BudgetList = Array<BudgetReference>;

export interface BudgetReference {
	id: string;
	name: string;
}

export interface Budget {
	name?: string;
	UUID: string;
	categories: Array<Category>;
	fromDB?: boolean;
}

export interface Category {
	UUID: string;
	name: string;
	total: number;
	isIncome: boolean;
	items: BudgetItem[];
}

export interface BudgetItem {
	UUID: string;
	name: string;
	value: number;
	frequency: FrequencyOptions | number;
	spendType: ItemSpendType;
}

export enum FrequencyOptions {
	Weekly = 'Weekly',
	Fortnightly = 'Fortnightly',
	Monthly = 'Monthly',
	Quarterly = 'Quarterly',
	Annually = 'Annually',
	/** Financial year for transactions */
	FinancialYear = 'Financial Year',
	/** Custom frequency for transaction table only */
	Custom = 'Custom',
	/** For transaction table only, to show all transactions */
	All = 'All',
}

export enum ItemSpendType {
	Unset = 'unset',
	Need = 'need',
	Want = 'want',
	Saving = 'saving',
	Income = 'income',
}

export const frequencyIdToStringMap = [
	'Weekly',
	'Fortnightly',
	'Monthly',
	'Quarterly',
	'Annually',
];

export const Months = {
	January: 1,
	February: 2,
	March: 3,
	April: 4,
	May: 5,
	June: 6,
	July: 7,
	August: 8,
	September: 9,
	October: 10,
	November: 11,
	December: 12,
};

export interface BudgetTotals {
	total: number;
	incomeTotal: number;
	expenseTotal: number;
	savingsTotal: number;
	surplus: number;
	categoryTotals: Map<string, number>;
}

export interface TransactionList {
	budgetId: string;
	transactionListId: string;
	transactions: Array<Transaction>;
}

export interface Transaction {
	UUID: string;
	date: DateTime;
	amount: string;
	label: string;
	categoryId: undefined | string;
	itemId: undefined | string;
	isTaxDeductable: boolean;
}

export interface SavedBudgetAndTransactionList {
	budget: SavedBudget;
	transactionLists: Array<SavedTransactionList>;
}

export interface SavedBudget {
	UUID: string;
	name?: string;
	categories: Array<SavedCategory>;
}

export interface SavedCategory {
	UUID: string;
	name: string;
	total: number;
	isIncome: boolean;
	items: Array<SavedItem>;
}

export interface SavedItem {
	UUID: string;
	name: string;
	value: number;
	frequency: string | number;
	spendType: string;
}

export interface SavedTransactionList {
	budgetId: string;
	transactionListId: string;
	transactions: Array<SavedTransaction>;
}

export interface SavedTransaction {
	UUID: string;
	date: string;
	amount: string;
	label: string;
	categoryId: string;
	itemId: string;
	isTaxDeductable: boolean;
}

export const PLACEHOLDER_CATEGORY = 'placeholder-category';

export interface DateRange {
	min: DateTime;
	max: DateTime;
}
