import {
	Budget,
	BudgetTotals,
	Category,
	FrequencyOptions,
	TransactionList,
} from '../../redux/Model';
import {
	calcAnnualValue,
	calcFortnightlyValue,
	calcMonthlyValue,
	calcQuarterlyValue,
	calcWeeklyValue,
} from '../../utils/BudgetUtils';
import { TransactionChartElement } from './TransactionChart';

export const computeTransactionChart = (
	budget: Budget,
	budgetTotals: BudgetTotals,
	budgetCategories: Category[],
	transactionList: TransactionList
): Array<TransactionChartElement> => {
	const data: Array<TransactionChartElement> = [];

	data.push({
		name: 'Uncategorised',
		total: 0,
		transactionTotal: 0,
		id: 'uncategorised',
	});

	budget.categories.forEach((category) => {
		if (category.isIncome) {
			data.push({
				name: category.name,
				total: Number.parseFloat(
					(budgetTotals.categoryTotals.get(category.UUID) || 0).toFixed(2)
				),
				transactionTotal: 0,
				id: category.UUID,
			});
		}

		if (!category.isIncome) {
			data.push({
				name: category.name,
				total:
					Number.parseFloat(
						(budgetTotals.categoryTotals.get(category.UUID) || 0).toFixed(2)
					) * -1,
				transactionTotal: 0,
				id: category.UUID,
			});
		}
	});

	transactionList.transactions.forEach((transaction) => {
		// If no category ID, transaction is uncategorised.
		if (transaction.categoryId === undefined) {
			const transactionAmount = Number.parseFloat(transaction.amount);
			data[0].transactionTotal += transactionAmount * -1;
			return;
		}

		const category = budgetCategories.find((category) => {
			return category.UUID === transaction.categoryId;
		});

		if (category) {
			let categoryEntry = data.find((entry) => entry.id === category.UUID);

			if (categoryEntry) {
				categoryEntry.transactionTotal += category.isIncome
					? Number.parseFloat(transaction.amount)
					: Number.parseFloat(transaction.amount) * -1;
			} else {
				console.warn('Could not find category entry in data for category!');
				data[0].transactionTotal += Number.parseFloat(transaction.amount) * -1;
			}
		} else {
			console.warn('Category not found!');
			data[0].transactionTotal += Number.parseFloat(transaction.amount) * -1;
		}
	});

	return data;
};

export const computeDrilldownTransactionChart = (
	transactionList: TransactionList,
	category: Category | null,
	frequency: FrequencyOptions
): Array<TransactionChartElement> => {
	const data: Array<TransactionChartElement> = [];

	if (!category) {
		return data;
	}

	const elementMap = new Map<string, TransactionChartElement>();

	category.items.forEach((item) => {
		let value = 0;
		switch (frequency) {
			case FrequencyOptions.Annually:
			case FrequencyOptions.All:
			case FrequencyOptions.FinancialYear: {
				value = calcAnnualValue(item.value, item.frequency as FrequencyOptions);
				break;
			}
			case FrequencyOptions.Quarterly: {
				value = calcQuarterlyValue(
					item.value,
					item.frequency as FrequencyOptions
				);
				break;
			}
			case FrequencyOptions.Monthly: {
				value = calcMonthlyValue(
					item.value,
					item.frequency as FrequencyOptions
				);
				break;
			}
			case FrequencyOptions.Fortnightly: {
				value = calcFortnightlyValue(
					item.value,
					item.frequency as FrequencyOptions
				);
				break;
			}
			case FrequencyOptions.Weekly: {
				value = calcWeeklyValue(item.value, item.frequency as FrequencyOptions);
				break;
			}
		}

		elementMap.set(item.UUID, {
			name: item.name,
			total: Number.parseFloat(value.toFixed(2)),
			transactionTotal: 0,
			id: item.UUID,
		});
	});

	const itemIds = category.items.map((i) => i.UUID);

	transactionList.transactions
		.filter((t) => itemIds.includes(t.itemId ?? ''))
		.forEach((transaction) => {
			const item = category.items.find((item) => {
				return item.UUID === transaction.itemId;
			});

			if (item) {
				let categoryEntry = elementMap.get(item.UUID);

				if (categoryEntry) {
					categoryEntry.transactionTotal +=
						Number.parseFloat(transaction.amount) * -1;
				} else {
					console.warn('Could not find category entry in data for category!');
				}
			}
		});

	const output = Array.from(elementMap.values());

	// Invert income as it shouldn't be negative
	if (category?.isIncome) {
		return output.map((e) => {
			if (e.transactionTotal < 0) {
				e.transactionTotal *= -1;
			}

			return e;
		});
	}

	return output;
};
