import { Budget, BudgetItem, Category } from '../Model';

export const findBudget = (
	budgets: Array<Budget>,
	budgetId: string
): Budget | undefined => {
	return budgets.find((budget) => budget.UUID === budgetId);
};

export const findBudgetIndex = (
	budgets: Array<Budget>,
	budgetId: string
): number => {
	return budgets.findIndex((budget) => {
		return budget.UUID === budgetId;
	});
};

export const findCategoryIndex = (
	categories: Array<Category>,
	categoryId: string
): number => {
	return categories.findIndex((category) => {
		return category.UUID === categoryId;
	});
};

export const findBudgetItemIndex = (
	items: Array<BudgetItem>,
	budgetItemId: string
): number => {
	return items.findIndex((item) => {
		return item.UUID === budgetItemId;
	});
};
