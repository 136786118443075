export const numberWithCommas = (x: number, fixedVal?: number) => {

	const stringVal: string = fixedVal !== undefined ? x.toFixed(fixedVal) : x.toString();

	return stringVal.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export const removeCharsFromNumberImport = (val: string) => {
	return val.replace('$', '').replace('+', '');
}

