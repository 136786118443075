import { Checkbox, FileUploader, Modal } from 'carbon-components-react';
import { parse, ParseConfig } from 'papaparse';
import React, { useCallback, useEffect, useState } from 'react';
import { Budget, Transaction, TransactionList } from '../../redux/Model';
import { v4 as uuidv4 } from 'uuid';
import { removeCharsFromNumberImport } from '../../utils/numberUtils';
import { DateTime } from 'luxon';
import TransactionTable from '../TransactionTable/TransactionTable';
import './ImportTransactions.scss';

export interface ImportTransactionsProps {
	open: boolean;
	budgets: Budget[];
	onRequestSubmit: (transactionList: TransactionList) => void;
	onRequestClose: () => void;
}

type OwnProps = ImportTransactionsProps;

const ImportTransactions: React.FC<OwnProps> = (props) => {
	const [currTransactionList, setCurrTransactionList] =
		useState<TransactionList | null>(null);
	const [hasHeaders, setHasHeaders] = useState<boolean>(false);
	const [currData, setCurrData] = useState<string | null>(null);
	const budgetId = props.budgets[0].UUID;

	const loadData = useCallback(
		(data: string, hasHeaders: boolean) => {
			const parseConfig: ParseConfig = {
				skipEmptyLines: true,
			};

			const parsedCSV = parse(data, parseConfig);

			const transactionList: TransactionList = {
				budgetId: budgetId,
				transactionListId: uuidv4(),
				transactions: [],
			};

			parsedCSV.data.forEach((row, index) => {
				if (index === 0 && hasHeaders) {
					return;
				}
				const dateComponents = (row[0] as string).split('/');
				const dateObject = {
					day: Number(dateComponents[0]),
					month: Number(dateComponents[1]),
					year: Number(dateComponents[2]),
				};
				try {
					const transaction: Transaction = {
						UUID: uuidv4(),
						date: DateTime.fromObject(dateObject),
						amount: removeCharsFromNumberImport(row[1]),
						label: removeCharsFromNumberImport(row[2]),
						categoryId: undefined,
						itemId: undefined,
						isTaxDeductable: false,
					};
					transactionList.transactions.push(transaction);
				} catch (e) {
					console.error('invalid row', e);
				}
			});

			setCurrTransactionList(transactionList);
		},
		[budgetId]
	);

	useEffect(() => {
		if (currData) {
			loadData(currData, hasHeaders);
		}
	}, [currData, hasHeaders, loadData]);

	const renderFileUploader = () => {
		return (
			<FileUploader
				accept={['.csv']}
				buttonKind="ghost"
				buttonLabel="Load Transaction List"
				filenameStatus="edit"
				onDelete={() => {
					setCurrTransactionList(null);
					setCurrData(null);
				}}
				onChange={(evt) => {
					const files = evt.target.files;
					if (files !== null && files[0]) {
						const reader = new FileReader();
						const file = files[0];

						reader.readAsText(file);

						reader.onloadend = () => {
							const data = reader.result as string;
							setCurrData(data);
						};
					}
				}}
			/>
		);
	};

	return (
		<Modal
			modalHeading="Import Transactions"
			open={props.open}
			onRequestSubmit={() => {
				if (!currTransactionList) {
					return;
				}
				props.onRequestSubmit(currTransactionList);
				setCurrTransactionList(null);
			}}
			onRequestClose={() => {
				setCurrData(null);
				setCurrTransactionList(null);
				props.onRequestClose();
			}}
			primaryButtonText="Import"
			secondaryButtonText="Cancel"
			className="importTransactions__modal"
			primaryButtonDisabled={currTransactionList === null}
		>
			<div className="importTransactions__modal-contents">
				<div>{renderFileUploader()}</div>
				<div>
					<Checkbox
						id="importTransactions__has-headers-checkbox"
						labelText="Has Headers"
						checked={hasHeaders}
						onChange={(val: boolean) => {
							setHasHeaders(val);
						}}
					/>
				</div>
				<div>
					{currTransactionList && (
						<TransactionTable
							transactionList={currTransactionList}
							transactionListIndex={0}
						/>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default ImportTransactions;
