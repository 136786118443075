import { BudgetAppState } from '../../storeModel';
import { UpdateBudgetItemAction } from './action';
import {
	findBudgetIndex,
	findBudgetItemIndex,
	findCategoryIndex,
} from '../reducerUtils';

const reduceUpdateBudgetItem = (
	prevState: BudgetAppState,
	action: UpdateBudgetItemAction
): BudgetAppState => {
	const budgetIndex = findBudgetIndex(prevState.budgets, action.budgetId);

	const budget = prevState.budgets[budgetIndex];

	if (!budget) {
		console.error(`Budget with ID ${action.budgetId} not found!`);
		return prevState;
	}

	const categoryIndex = findCategoryIndex(budget.categories, action.categoryId);

	const category = budget.categories[categoryIndex];

	if (!category) {
		console.error(`Category with ID ${action.categoryId} not found!`);
		return prevState;
	}

	const itemIndex = findBudgetItemIndex(category.items, action.budgetItem.UUID);

	const newItemsList = [...category.items];
	newItemsList[itemIndex] = { ...action.budgetItem };

	const newCategory = { ...category };
	newCategory.items = newItemsList;

	const newBudget = { ...budget };
	newBudget.categories = [...budget.categories];
	newBudget.categories[categoryIndex] = newCategory;

	const nextState = {
		...prevState,
		budgets: [...prevState.budgets],
	};
	nextState.budgets[budgetIndex] = newBudget;

	return nextState;
};

export default reduceUpdateBudgetItem;
