
import { globalModalInitialState } from '../../state';
import { BudgetAppState } from '../../storeModel';
import { CloseGlobalModalAction } from './action';

export const reduceCloseGlobalModal = (
	prevState: BudgetAppState,
	action: CloseGlobalModalAction
): BudgetAppState => {
	return {...prevState,
		globalModalState: {...globalModalInitialState},
	};
}