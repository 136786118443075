import { DateTime } from 'luxon';
import { TransactionList } from '../redux/Model';

export const dummyTransactionList: TransactionList = {
	budgetId: '5a661d55-3329-4930-b016-9632d28e7701',
	transactionListId: '625d2418-cce3-4ff3-ac36-9973c487aa1f',
	transactions: [
		{
			UUID: 'fc1aceaf-0081-44c4-abf4-206f2373bfe7',
			date: DateTime.fromISO('2020-09-28T00:00:00.000+10:00'),
			amount: '-698.02',
			label: 'Nulla Semper Tellus Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '913155c3-5c96-4767-806e-a7705dcaeb96',
			date: DateTime.fromISO('2020-09-18T00:00:00.000+10:00'),
			amount: '-943.51',
			label: 'Velit In Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '39a45f9a-1c45-487e-9492-43177bc133a3',
			date: DateTime.fromISO('2021-02-17T00:00:00.000+11:00'),
			amount: '-425.38',
			label: 'Est Congue A Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '0df25986-4bff-4225-8c19-7e1af4ee63a7',
			date: DateTime.fromISO('2021-01-05T00:00:00.000+11:00'),
			amount: '-236.17',
			label: 'Ipsum Dolor Company',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e42511e8-aadd-426d-b4dd-42cf6cbde57e',
			date: DateTime.fromISO('2021-01-13T00:00:00.000+11:00'),
			amount: '-080.11',
			label: 'Nam Nulla Ltd',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '4d603e48-12c9-4dc8-bd64-a1459b452ae2',
			date: DateTime.fromISO('2021-01-31T00:00:00.000+11:00'),
			amount: '-059.90',
			label: 'Nullam PC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '9c0fe143-6108-48fe-8a77-8c6b05ce2732',
			date: DateTime.fromISO('2021-02-26T00:00:00.000+11:00'),
			amount: '-964.46',
			label: 'Sem Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '20818368-9439-4932-ab4c-43439f6dad6b',
			date: DateTime.fromISO('2021-03-06T00:00:00.000+11:00'),
			amount: '-619.85',
			label: 'Nam Porttitor Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'f84aa8c4-a687-4434-a289-e8b9102ff5a3',
			date: DateTime.fromISO('2021-03-04T00:00:00.000+11:00'),
			amount: '-585.09',
			label: 'Quis Pede PC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'c99bc71d-0331-4e10-8bfa-02debc86b025',
			date: DateTime.fromISO('2021-05-12T00:00:00.000+10:00'),
			amount: '-493.11',
			label: 'Eros Proin Ultrices LLP',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e31c7f90-df73-4cc9-99c4-b4c0e045babc',
			date: DateTime.fromISO('2021-02-12T00:00:00.000+11:00'),
			amount: '-356.36',
			label: 'Ipsum Donec Sollicitudin Ltd',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '33252f67-4d5a-4b49-b792-2fcf0ddaad5e',
			date: DateTime.fromISO('2021-06-02T00:00:00.000+10:00'),
			amount: '-161.17',
			label: 'Ligula Incorporated',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e73fc292-f520-4612-a3d8-f80f99589c55',
			date: DateTime.fromISO('2020-07-24T00:00:00.000+10:00'),
			amount: '-902.96',
			label: 'Nec Tempus LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '562206c7-e790-48ea-9e26-ea7a796e771a',
			date: DateTime.fromISO('2020-11-05T00:00:00.000+11:00'),
			amount: '-038.69',
			label: 'Nec Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '40693865-c41b-4d8c-913d-bc5e24419a8d',
			date: DateTime.fromISO('2021-02-04T00:00:00.000+11:00'),
			amount: '-357.12',
			label: 'Ultrices A Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '95814518-d4aa-4ab0-8dc4-1f485602782a',
			date: DateTime.fromISO('2021-02-25T00:00:00.000+11:00'),
			amount: '-977.18',
			label: 'Nec PC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'a3f2d731-dca6-43b9-9c11-ee3982dc3bd9',
			date: DateTime.fromISO('2020-07-24T00:00:00.000+10:00'),
			amount: '-600.99',
			label: 'Nibh Donec Est Industries',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '48b856fb-cb7e-49c8-b067-1f8303eece4d',
			date: DateTime.fromISO('2020-11-19T00:00:00.000+11:00'),
			amount: '-226.23',
			label: 'Cursus Nunc Inc.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'b64a82af-2c3b-43ab-b592-ef3f19891351',
			date: DateTime.fromISO('2020-09-17T00:00:00.000+10:00'),
			amount: '-724.33',
			label: 'Lacus Quisque Imperdiet Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '9a4e6b8d-5972-4eeb-b8f8-186c7752e587',
			date: DateTime.fromISO('2021-01-02T00:00:00.000+11:00'),
			amount: '-119.84',
			label: 'Nisl Quisque Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'ab7b379e-e767-4878-af91-2d6d443c7c73',
			date: DateTime.fromISO('2020-07-22T00:00:00.000+10:00'),
			amount: '-512.47',
			label: 'Donec Luctus Aliquet Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'da2763f8-c622-443b-ab9c-739a1922431d',
			date: DateTime.fromISO('2020-11-22T00:00:00.000+11:00'),
			amount: '-083.53',
			label: 'Nascetur Ridiculus Company',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '9f541bfb-645d-49d2-b2bf-0947206d9371',
			date: DateTime.fromISO('2021-04-05T00:00:00.000+10:00'),
			amount: '-109.53',
			label: 'Eleifend Non Dapibus LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '4159b29d-7925-4794-84fc-8b931f1de523',
			date: DateTime.fromISO('2020-10-05T00:00:00.000+11:00'),
			amount: '-600.48',
			label: 'Sem Ut Cursus Ltd',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '7e9536ee-6490-4a0b-ae3e-7af406d87757',
			date: DateTime.fromISO('2021-02-09T00:00:00.000+11:00'),
			amount: '-414.54',
			label: 'Integer Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '5e36e9a6-f697-4883-b5e0-7ae134e2417f',
			date: DateTime.fromISO('2020-09-24T00:00:00.000+10:00'),
			amount: '-937.99',
			label: 'Facilisis Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '01caa457-f7d6-4845-844a-520cd3910bc5',
			date: DateTime.fromISO('2020-07-20T00:00:00.000+10:00'),
			amount: '-685.39',
			label: 'Felis Nulla Tempor Incorporated',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '7b7fa4dd-40d8-4fe5-95c5-0eaf609c5510',
			date: DateTime.fromISO('2021-01-27T00:00:00.000+11:00'),
			amount: '-127.61',
			label: 'Eleifend Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'c3142ea0-5b59-4083-9c28-77ccd8dbcd5a',
			date: DateTime.fromISO('2020-09-27T00:00:00.000+10:00'),
			amount: '-679.12',
			label: 'Venenatis Lacus Etiam Company',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '2d8026a0-0bd8-4aab-8f95-56b1f366f6c0',
			date: DateTime.fromISO('2021-04-13T00:00:00.000+10:00'),
			amount: '-962.68',
			label: 'Aliquam Vulputate Ullamcorper Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '0982e6f2-b338-4874-ad62-1addd9933aaf',
			date: DateTime.fromISO('2021-03-28T00:00:00.000+11:00'),
			amount: '-978.64',
			label: 'Posuere Vulputate Lacus Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '0911d938-8fd4-4853-8f23-f801132afaf6',
			date: DateTime.fromISO('2020-10-04T00:00:00.000+10:00'),
			amount: '-403.48',
			label: 'Enim Nec Ltd',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e3dbc9b5-db56-4874-9c97-ef57d1e1c692',
			date: DateTime.fromISO('2020-12-22T00:00:00.000+11:00'),
			amount: '-366.98',
			label: 'Ultrices Mauris Industries',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '28117a54-d544-43d7-b56a-c04ea23214b4',
			date: DateTime.fromISO('2021-02-18T00:00:00.000+11:00'),
			amount: '-387.92',
			label: 'Erat Eget Tincidunt Corp.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '0669e579-5801-48fd-a4b1-de5568f6d202',
			date: DateTime.fromISO('2020-11-02T00:00:00.000+11:00'),
			amount: '-463.74',
			label: 'Ut Sem Nulla Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'a26a4fcd-03df-4667-ad0e-1942a416db0a',
			date: DateTime.fromISO('2021-01-18T00:00:00.000+11:00'),
			amount: '-375.96',
			label: 'Penatibus Et Magnis LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'c5d90525-5466-40f8-8e70-ed1ef2012567',
			date: DateTime.fromISO('2021-03-13T00:00:00.000+11:00'),
			amount: '-347.42',
			label: 'Sapien Gravida Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'f77e4a66-6e4c-426b-b9f5-9520c48960cc',
			date: DateTime.fromISO('2021-05-29T00:00:00.000+10:00'),
			amount: '-444.72',
			label: 'Bibendum Sed Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '8d5a5b6c-c7b6-4431-af80-7245d2458177',
			date: DateTime.fromISO('2021-03-08T00:00:00.000+11:00'),
			amount: '-269.98',
			label: 'Semper Pretium Neque LLP',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '7aeefe44-8bdc-4658-9429-4e3f18436930',
			date: DateTime.fromISO('2021-05-03T00:00:00.000+10:00'),
			amount: '-564.76',
			label: 'Elit Pede Malesuada LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '90803535-d16d-4508-8926-6a0ab729ed4b',
			date: DateTime.fromISO('2020-08-14T00:00:00.000+10:00'),
			amount: '-856.64',
			label: 'Sem Magna Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e358af22-d710-48ac-b733-064c1dc8dddb',
			date: DateTime.fromISO('2021-02-28T00:00:00.000+11:00'),
			amount: '-222.59',
			label: 'Aliquam Adipiscing Lacus Ltd',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '31e1ee81-e96c-4b04-a737-a431bd05dbae',
			date: DateTime.fromISO('2020-10-01T00:00:00.000+10:00'),
			amount: '-739.45',
			label: 'Vel Quam LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'f8002ab1-e5dc-443f-8645-62a153e193a9',
			date: DateTime.fromISO('2021-05-13T00:00:00.000+10:00'),
			amount: '-335.76',
			label: 'Elit Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '97904f1a-738a-41bb-af5c-3d9581ecbdaa',
			date: DateTime.fromISO('2021-03-21T00:00:00.000+11:00'),
			amount: '-471.23',
			label: 'Magna A Tortor Corp.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'ead82d15-c358-4a43-9f1f-2253713c03d3',
			date: DateTime.fromISO('2021-06-07T00:00:00.000+10:00'),
			amount: '-745.89',
			label: 'Molestie Tortor Nibh LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '004d271c-ee22-4f97-82df-f31276b63ec8',
			date: DateTime.fromISO('2020-08-09T00:00:00.000+10:00'),
			amount: '-41.42',
			label: 'Sapien Aenean Corporation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'f3a2e830-cdad-47cf-8a80-96396dfa9f02',
			date: DateTime.fromISO('2021-04-25T00:00:00.000+10:00'),
			amount: '-130.53',
			label: 'Proin Vel Nisl PC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '3a200956-571b-4f0f-a334-47405c9d67a4',
			date: DateTime.fromISO('2020-12-19T00:00:00.000+11:00'),
			amount: '-47.03',
			label: 'Phasellus Nulla Integer Ltd',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '73c49b43-fa9b-4462-ac34-eec81a4f5b89',
			date: DateTime.fromISO('2021-03-07T00:00:00.000+11:00'),
			amount: '-814.55',
			label: 'At Velit Corporation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '2b9a097a-49f9-4212-988f-f51392a0c4b6',
			date: DateTime.fromISO('2020-09-13T00:00:00.000+10:00'),
			amount: '-95.02',
			label: 'Pede Nonummy Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'db9cce38-71d8-4488-9f85-03de31e4f87f',
			date: DateTime.fromISO('2020-09-08T00:00:00.000+10:00'),
			amount: '-078.92',
			label: 'Fusce Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '7c4e2720-bd93-4f0a-8179-73ca84bc0138',
			date: DateTime.fromISO('2020-08-06T00:00:00.000+10:00'),
			amount: '-232.60',
			label: 'Ligula Aenean Euismod Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'c9a1fcdb-2119-4d26-9608-43f8cb4cb774',
			date: DateTime.fromISO('2021-05-22T00:00:00.000+10:00'),
			amount: '-763.21',
			label: 'Vestibulum Massa Rutrum Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'fbe0935c-9b8a-492c-92ed-64bd00a07b0b',
			date: DateTime.fromISO('2021-01-11T00:00:00.000+11:00'),
			amount: '-352.66',
			label: 'Odio Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'ec909b03-599c-429e-84f0-694e8937b150',
			date: DateTime.fromISO('2020-09-13T00:00:00.000+10:00'),
			amount: '-441.35',
			label: 'Magna Duis Industries',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '57191186-de5f-4a5b-822f-3e3f51e14df3',
			date: DateTime.fromISO('2021-04-07T00:00:00.000+10:00'),
			amount: '-736.64',
			label: 'Natoque Penatibus Et Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '659086dd-0bf5-4d0b-b0b5-748547193373',
			date: DateTime.fromISO('2021-02-16T00:00:00.000+11:00'),
			amount: '-381.02',
			label: 'In Faucibus LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '848ca99d-980b-4786-b4c0-372ff5fd28e1',
			date: DateTime.fromISO('2021-03-12T00:00:00.000+11:00'),
			amount: '-907.60',
			label: 'Molestie Industries',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e570f4e7-7fe8-4722-9f85-f0728c3fb120',
			date: DateTime.fromISO('2020-07-09T00:00:00.000+10:00'),
			amount: '-418.96',
			label: 'Gravida Molestie Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'b2854e73-323b-472d-bb5c-8a7f0d4950ab',
			date: DateTime.fromISO('2020-09-22T00:00:00.000+10:00'),
			amount: '-808.32',
			label: 'Integer Vulputate Company',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '3ecd8099-83ec-4560-8ee6-2a34deb2e2ab',
			date: DateTime.fromISO('2020-09-06T00:00:00.000+10:00'),
			amount: '-57.86',
			label: 'Aliquam Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '4360ff82-29ca-4dc1-8995-36135b3afd1e',
			date: DateTime.fromISO('2020-11-17T00:00:00.000+11:00'),
			amount: '-900.57',
			label: 'Amet Ltd',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'ab8053d1-a8d1-4119-b981-61a58e8ad349',
			date: DateTime.fromISO('2021-05-06T00:00:00.000+10:00'),
			amount: '-067.70',
			label: 'Arcu Vivamus PC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'f66b77f2-9003-4f5d-822d-377107862052',
			date: DateTime.fromISO('2020-09-07T00:00:00.000+10:00'),
			amount: '-066.77',
			label: 'Urna Ut Tincidunt LLP',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '0c674d36-b265-4585-a66a-3322f897906c',
			date: DateTime.fromISO('2020-11-04T00:00:00.000+11:00'),
			amount: '-031.09',
			label: 'Lectus Ante PC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '2c53c4ce-10e7-4669-8861-16e1ba67e425',
			date: DateTime.fromISO('2021-02-07T00:00:00.000+11:00'),
			amount: '-681.99',
			label: 'Egestas LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e21af3f2-81c8-440e-b2d2-a2b344c8daef',
			date: DateTime.fromISO('2020-12-25T00:00:00.000+11:00'),
			amount: '-999.07',
			label: 'Lobortis Risus In Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '90baba5f-32f8-4474-9bee-96a81ce6c88b',
			date: DateTime.fromISO('2021-01-27T00:00:00.000+11:00'),
			amount: '-789.68',
			label: 'Quisque Ac Libero Incorporated',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '3eaa4e5f-9d21-4f13-a24a-55e9818532be',
			date: DateTime.fromISO('2020-07-23T00:00:00.000+10:00'),
			amount: '-404.73',
			label: 'Sed Est Nunc Ltd',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '59acc3cc-df6d-41a6-9723-5f3b6dd63459',
			date: DateTime.fromISO('2020-10-18T00:00:00.000+11:00'),
			amount: '-290.23',
			label: 'Eleifend Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '6a593272-53de-47a6-9539-a19f6d5a59ad',
			date: DateTime.fromISO('2020-10-10T00:00:00.000+11:00'),
			amount: '-017.17',
			label: 'Augue Ac Ipsum Incorporated',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '2b208f98-473f-49c6-a4d0-da6dc341c83a',
			date: DateTime.fromISO('2021-05-31T00:00:00.000+10:00'),
			amount: '-172.83',
			label: 'Tellus Incorporated',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '7a0f7b19-e306-43f4-ab88-76f20974e0b0',
			date: DateTime.fromISO('2021-03-15T00:00:00.000+11:00'),
			amount: '-221.72',
			label: 'Et Magnis Dis Corp.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '9b93ea59-7925-4d1a-9528-e7d31119b187',
			date: DateTime.fromISO('2020-07-21T00:00:00.000+10:00'),
			amount: '-183.45',
			label: 'Dui Suspendisse Ac Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e8b9950c-2136-48cb-87e8-1b8d9e545054',
			date: DateTime.fromISO('2021-05-30T00:00:00.000+10:00'),
			amount: '-275.81',
			label: 'Mauris Morbi Non Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '0cda74c9-ce51-4cf9-b7e9-a8a2ebd4bece',
			date: DateTime.fromISO('2021-05-05T00:00:00.000+10:00'),
			amount: '-853.82',
			label: 'Vitae Orci Inc.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'edd712e0-5e12-4add-b14b-b4dbf0f50e0d',
			date: DateTime.fromISO('2021-05-25T00:00:00.000+10:00'),
			amount: '-749.97',
			label: 'Urna Corp.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '4308307c-607d-4e8d-92c9-fabf1a6640a0',
			date: DateTime.fromISO('2020-09-11T00:00:00.000+10:00'),
			amount: '-184.20',
			label: 'A Scelerisque Sed Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '8c3fd712-36b3-4e4a-85ca-9ffe81e11019',
			date: DateTime.fromISO('2020-11-30T00:00:00.000+11:00'),
			amount: '-674.71',
			label: 'Nascetur Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '5fde2bbf-c753-4adf-bb6b-bec78fff7464',
			date: DateTime.fromISO('2021-02-12T00:00:00.000+11:00'),
			amount: '-143.29',
			label: 'Elementum Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '8f53848b-9591-41bf-93a8-c1d3be6c0435',
			date: DateTime.fromISO('2020-11-13T00:00:00.000+11:00'),
			amount: '-770.92',
			label: 'Proin Vel Nisl Corp.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '453fe61a-ff11-4180-b809-de4da5bb42da',
			date: DateTime.fromISO('2020-07-18T00:00:00.000+10:00'),
			amount: '-311.17',
			label: 'Lobortis Ultrices LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '4619ab5c-e791-48d7-b607-ac02b9f5c08e',
			date: DateTime.fromISO('2021-01-10T00:00:00.000+11:00'),
			amount: '-561.31',
			label: 'Sit Amet Ante Company',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '0dd72a40-e333-4a9c-b13e-8be7bc75a59e',
			date: DateTime.fromISO('2021-02-14T00:00:00.000+11:00'),
			amount: '-084.70',
			label: 'Pede Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '95b6fb4d-7ba9-48e4-ac59-d87c4d2e54d5',
			date: DateTime.fromISO('2020-10-15T00:00:00.000+11:00'),
			amount: '-449.40',
			label: 'Tempor Est Corp.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '8269c838-771a-40d8-b478-c92b2b0fc23a',
			date: DateTime.fromISO('2020-09-08T00:00:00.000+10:00'),
			amount: '-353.91',
			label: 'Consequat Lectus Sit Ltd',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '29c1b798-7314-4df1-a688-60228eef325a',
			date: DateTime.fromISO('2020-09-17T00:00:00.000+10:00'),
			amount: '-939.29',
			label: 'Auctor Velit PC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '4d37826a-262c-4e5d-97c5-109d22d17ec9',
			date: DateTime.fromISO('2021-04-16T00:00:00.000+10:00'),
			amount: '-297.33',
			label: 'Per Conubia Nostra Company',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'f60f6c00-df8e-4684-94ee-dc6e47e07693',
			date: DateTime.fromISO('2021-05-09T00:00:00.000+10:00'),
			amount: '-453.77',
			label: 'Et Malesuada Fames Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '41439958-fb2a-41a0-919e-8308ffe4d5dd',
			date: DateTime.fromISO('2020-07-05T00:00:00.000+10:00'),
			amount: '-244.34',
			label: 'Sociis Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '65703160-75fc-4d84-9524-3c752e83108b',
			date: DateTime.fromISO('2020-08-04T00:00:00.000+10:00'),
			amount: '-634.61',
			label: 'Consequat Purus Inc.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'b1955cce-00ae-4de1-bcf0-e2b3a6c735ef',
			date: DateTime.fromISO('2021-01-11T00:00:00.000+11:00'),
			amount: '-155.39',
			label: 'Et PC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'acd42568-70b4-47ed-aee0-6a368db1c266',
			date: DateTime.fromISO('2020-08-11T00:00:00.000+10:00'),
			amount: '-203.56',
			label: 'Lectus Rutrum Corp.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '4466867f-a113-4399-8c5d-9b3c6e03d86e',
			date: DateTime.fromISO('2020-11-15T00:00:00.000+11:00'),
			amount: '-682.97',
			label: 'Rhoncus LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '8203ed29-5090-4cf0-867c-7749fd13f18f',
			date: DateTime.fromISO('2020-09-10T00:00:00.000+10:00'),
			amount: '-718.04',
			label: 'Dis LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '03f762f2-f804-4172-b52d-223514fa7716',
			date: DateTime.fromISO('2020-10-28T00:00:00.000+11:00'),
			amount: '-613.85',
			label: 'Nunc Corp.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'f627d4e1-0654-4c06-b8fb-75bcc7a63541',
			date: DateTime.fromISO('2020-09-01T00:00:00.000+10:00'),
			amount: '-413.76',
			label: 'Ac Ltd',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'd6d9b6f6-2a63-4df9-b1d7-5c992430b75b',
			date: DateTime.fromISO('2021-04-29T00:00:00.000+10:00'),
			amount: '-625.78',
			label: 'Aliquam Ultrices Company',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '841418a7-4c2c-4ea0-b857-744d3a024202',
			date: DateTime.fromISO('2021-01-18T00:00:00.000+11:00'),
			amount: '-840.51',
			label: 'Ultrices Associatesdate',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '8ee74366-caa5-412a-a06d-778f39aadbb6',
			date: DateTime.fromISO('2021-04-24T00:00:00.000+10:00'),
			amount: '-680.10',
			label: 'Nullam Enim Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '15558ea9-de8c-4433-a74b-fc60adc252ce',
			date: DateTime.fromISO('2021-06-10T00:00:00.000+10:00'),
			amount: '-296.41',
			label: 'Tellus Sem PC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '12effec9-2537-45b0-ba4f-0a4fe11c5317',
			date: DateTime.fromISO('2020-12-21T00:00:00.000+11:00'),
			amount: '-016.81',
			label: 'Ut Ltd',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '31100795-ce17-4cf7-bd53-7dd72f9d0a52',
			date: DateTime.fromISO('2021-01-18T00:00:00.000+11:00'),
			amount: '-497.76',
			label: 'Donec Nibh Incorporated',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '2c790473-3256-44e9-8ece-a2874ee9172d',
			date: DateTime.fromISO('2021-01-29T00:00:00.000+11:00'),
			amount: '-529.73',
			label: 'Ut Eros Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '6989fbf9-3d4d-43f9-9fdf-e57f1ccdaa21',
			date: DateTime.fromISO('2020-12-27T00:00:00.000+11:00'),
			amount: '-556.29',
			label: 'Lacinia LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '24ccdbea-f49b-4073-8f59-60decd0bf01c',
			date: DateTime.fromISO('2020-07-18T00:00:00.000+10:00'),
			amount: '-248.46',
			label: 'Mollis Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'c107adf7-4e88-4e5e-aae3-36809c17cf9c',
			date: DateTime.fromISO('2020-09-02T00:00:00.000+10:00'),
			amount: '-335.14',
			label: 'Ad PC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'f0771d88-972b-488b-9e5c-7c2339153d98',
			date: DateTime.fromISO('2020-10-29T00:00:00.000+11:00'),
			amount: '-802.74',
			label: 'Nec Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'c4e954a7-5360-4029-b1dc-c39c8a072400',
			date: DateTime.fromISO('2021-03-19T00:00:00.000+11:00'),
			amount: '-080.31',
			label: 'Eget Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '7ab048ae-0994-4c06-8370-6da75fa40c63',
			date: DateTime.fromISO('2021-05-10T00:00:00.000+10:00'),
			amount: '-649.54',
			label: 'Amet Orci Inc.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '3e7f9def-371c-401b-9640-7f5723c0c447',
			date: DateTime.fromISO('2021-05-29T00:00:00.000+10:00'),
			amount: '-86.61',
			label: 'Aenean Massa Industries',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'afe6bc16-2422-40ff-b183-cbd389e4b62c',
			date: DateTime.fromISO('2021-06-29T00:00:00.000+10:00'),
			amount: '-354.88',
			label: 'Vel Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '8dd6a445-7f1a-4e25-9b44-bcfa5894cd1e',
			date: DateTime.fromISO('2020-09-30T00:00:00.000+10:00'),
			amount: '-468.80',
			label: 'Amet Industries',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '0f54ecc1-58ec-4565-bbe3-50de0a98a5e5',
			date: DateTime.fromISO('2020-11-14T00:00:00.000+11:00'),
			amount: '-84.54',
			label: 'Lacinia Vitae Corp.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '08e8c845-98a5-4508-a505-6725c9bdb7a7',
			date: DateTime.fromISO('2020-12-02T00:00:00.000+11:00'),
			amount: '-240.18',
			label: 'Amet Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'efb274f4-1670-471a-a945-8a72de565208',
			date: DateTime.fromISO('2021-02-22T00:00:00.000+11:00'),
			amount: '-881.90',
			label: 'Tristique Neque Venenatis Industries',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'fbf48042-5626-4c28-88c7-188cdae40457',
			date: DateTime.fromISO('2021-03-09T00:00:00.000+11:00'),
			amount: '-991.03',
			label: 'Malesuada Fames Ac Company',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '33df2fbc-1a58-4250-ac83-a150fc771230',
			date: DateTime.fromISO('2020-11-29T00:00:00.000+11:00'),
			amount: '-494.73',
			label: 'Duis Sit Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '1fcc6705-d82f-46c8-8316-6a09482f5389',
			date: DateTime.fromISO('2020-08-17T00:00:00.000+10:00'),
			amount: '-209.01',
			label: 'Lacinia Orci Industries',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'f4a31b2b-8527-428a-a277-1f509d3dfad2',
			date: DateTime.fromISO('2020-12-12T00:00:00.000+11:00'),
			amount: '-367.25',
			label: 'Adipiscing LLP',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e0d18bb7-2a1d-414e-a1da-2b9c3d6b87fd',
			date: DateTime.fromISO('2020-12-16T00:00:00.000+11:00'),
			amount: '-248.32',
			label: 'Viverra Inc.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'bd9395e1-0cc4-4367-a26f-74db7ea7ace0',
			date: DateTime.fromISO('2020-07-22T00:00:00.000+10:00'),
			amount: '-369.36',
			label: 'Erat Volutpat Nulla Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '28f19a90-331c-454c-8fe8-07038eeb6693',
			date: DateTime.fromISO('2021-06-16T00:00:00.000+10:00'),
			amount: '-349.75',
			label: 'Dapibus LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '68a3b4c8-c607-4fbf-9995-09aed436ec62',
			date: DateTime.fromISO('2020-11-27T00:00:00.000+11:00'),
			amount: '-977.63',
			label: 'Mi Inc.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '4d8b3104-1852-49a4-9893-ce1708fde633',
			date: DateTime.fromISO('2021-04-20T00:00:00.000+10:00'),
			amount: '-704.30',
			label: 'Risus Morbi Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '282cfeb2-107e-4836-bbb4-2945be25f8c6',
			date: DateTime.fromISO('2020-07-05T00:00:00.000+10:00'),
			amount: '-462.77',
			label: 'Etiam Gravida Molestie Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'a4b005e4-3ff3-4ac5-8964-fb49d45d812b',
			date: DateTime.fromISO('2020-09-15T00:00:00.000+10:00'),
			amount: '-5.97',
			label: 'Nibh LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '0773a9ee-b5b0-4095-aa77-27c2def06646',
			date: DateTime.fromISO('2021-02-18T00:00:00.000+11:00'),
			amount: '-863.87',
			label: 'Pede LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '0a3e156a-15f2-4e83-96db-d203d5de6d14',
			date: DateTime.fromISO('2020-07-26T00:00:00.000+10:00'),
			amount: '-307.32',
			label: 'Donec Felis Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'a5b6297b-2811-420e-b91d-ce841faa4718',
			date: DateTime.fromISO('2020-11-23T00:00:00.000+11:00'),
			amount: '-793.44',
			label: 'Sed Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'ab74cf21-5f29-4df3-953f-1bf937839579',
			date: DateTime.fromISO('2021-04-30T00:00:00.000+10:00'),
			amount: '-995.91',
			label: 'Dolor Sit Amet Company',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'dd60bfe1-893e-4240-af2f-98f821a1d060',
			date: DateTime.fromISO('2020-12-12T00:00:00.000+11:00'),
			amount: '-886.87',
			label: 'Aliquam Nec Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '1bc1f4f9-f831-4b92-b155-f57e71c6db86',
			date: DateTime.fromISO('2021-05-08T00:00:00.000+10:00'),
			amount: '-813.18',
			label: 'Vivamus Corporation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '2c7600af-40c4-4d1f-8600-12004cbe0a5f',
			date: DateTime.fromISO('2021-04-15T00:00:00.000+10:00'),
			amount: '-292.99',
			label: 'Risus Odio Corporation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'fc69b3f3-6c00-4eae-94ff-03416833bec4',
			date: DateTime.fromISO('2021-02-27T00:00:00.000+11:00'),
			amount: '-907.16',
			label: 'Ultrices Posuere Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'aad6c99c-f7c9-48b7-a696-8478673511ce',
			date: DateTime.fromISO('2021-06-22T00:00:00.000+10:00'),
			amount: '-877.77',
			label: 'Euismod Est Arcu Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '913b408c-306f-4c80-a7b5-c5fe5345486e',
			date: DateTime.fromISO('2020-11-11T00:00:00.000+11:00'),
			amount: '-688.34',
			label: 'Justo Sit Amet Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'b51cac1b-1f5d-4221-8153-20bf1a446f5a',
			date: DateTime.fromISO('2020-07-28T00:00:00.000+10:00'),
			amount: '-697.22',
			label: 'Risus LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '853b0c77-f080-467a-972d-3e857dc3da29',
			date: DateTime.fromISO('2020-11-24T00:00:00.000+11:00'),
			amount: '-508.46',
			label: 'Vitae Orci Phasellus Industries',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '84bb79e5-27a2-4e92-9421-2fbbc9c7a346',
			date: DateTime.fromISO('2021-01-10T00:00:00.000+11:00'),
			amount: '-187.75',
			label: 'Duis Cursus Diam Company',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'f7d3bc24-4dda-4086-b208-0f205e265901',
			date: DateTime.fromISO('2020-08-16T00:00:00.000+10:00'),
			amount: '-177.65',
			label: 'Ac Arcu Nunc LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '48ecdcf6-0b57-4278-9049-9026e26f10c9',
			date: DateTime.fromISO('2020-08-25T00:00:00.000+10:00'),
			amount: '-2.10',
			label: 'Eu Corporation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'a78e48c8-2668-4224-9125-8d874323b20d',
			date: DateTime.fromISO('2021-05-19T00:00:00.000+10:00'),
			amount: '-611.36',
			label: 'Felis Purus LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'ed0b2017-d3d6-41e3-a230-2abddc37d345',
			date: DateTime.fromISO('2020-08-11T00:00:00.000+10:00'),
			amount: '-037.52',
			label: 'Consectetuer Adipiscing Elit Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '2e3af800-4725-4b9d-8a34-ac005a08587c',
			date: DateTime.fromISO('2021-01-17T00:00:00.000+11:00'),
			amount: '-310.11',
			label: 'Enim Gravida Company',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '999fb714-a9e1-42c6-b6b1-511dfc2ee9df',
			date: DateTime.fromISO('2020-11-03T00:00:00.000+11:00'),
			amount: '-886.14',
			label: 'A Incorporated',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '1217ab58-8a79-4167-a47b-84a7d51b597a',
			date: DateTime.fromISO('2021-02-11T00:00:00.000+11:00'),
			amount: '-415.14',
			label: 'Ut Semper Pretium Corporation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '3e3a1147-f637-4ff1-9150-5f3692e7be56',
			date: DateTime.fromISO('2020-08-13T00:00:00.000+10:00'),
			amount: '-666.69',
			label: 'Eu Odio Tristique Incorporated',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '373c34ec-5043-41f8-a3aa-3d68e39b2bb0',
			date: DateTime.fromISO('2021-05-19T00:00:00.000+10:00'),
			amount: '-086.00',
			label: 'Sapien Incorporated',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e378c22e-922e-494f-967e-f8452593666e',
			date: DateTime.fromISO('2021-03-20T00:00:00.000+11:00'),
			amount: '-859.05',
			label: 'Aliquet Inc.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e602bc10-70ef-46d1-a7ea-9ca53011313f',
			date: DateTime.fromISO('2020-09-01T00:00:00.000+10:00'),
			amount: '-488.53',
			label: 'Nam Nulla Magna Incorporated',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e5d49432-974f-4da7-bb31-40aceccb1c2f',
			date: DateTime.fromISO('2021-06-18T00:00:00.000+10:00'),
			amount: '-158.45',
			label: 'Dolor Sit Corporation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '77c30355-766c-4496-84df-3f5204bab562',
			date: DateTime.fromISO('2021-01-17T00:00:00.000+11:00'),
			amount: '-145.48',
			label: 'Nisl Maecenas Inc.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'a647f8ee-6804-42b7-a90f-2a6450aa698b',
			date: DateTime.fromISO('2020-11-02T00:00:00.000+11:00'),
			amount: '-659.87',
			label: 'Non PC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '71417cd2-31e0-4638-ab92-98f5bd3aaf7f',
			date: DateTime.fromISO('2021-05-09T00:00:00.000+10:00'),
			amount: '-013.46',
			label: 'Ut Pharetra Sed Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '389127ed-3391-421c-ba5d-bba8ede3374f',
			date: DateTime.fromISO('2020-10-20T00:00:00.000+11:00'),
			amount: '-252.75',
			label: 'Magnis Dis Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '75488b7d-052d-430d-864c-a768a0372c96',
			date: DateTime.fromISO('2020-12-25T00:00:00.000+11:00'),
			amount: '-826.45',
			label: 'Cras Dolor Dolor Inc.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '7a34ff29-bc00-4616-80a3-2d7872ea8c62',
			date: DateTime.fromISO('2021-01-26T00:00:00.000+11:00'),
			amount: '-338.63',
			label: 'Ipsum Sodales Purus Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'da31a960-01bf-4ed1-9afc-9e7500054d40',
			date: DateTime.fromISO('2020-11-23T00:00:00.000+11:00'),
			amount: '-525.07',
			label: 'Massa Quisque Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '2df950dc-289d-4085-996d-a471273e5b46',
			date: DateTime.fromISO('2021-02-25T00:00:00.000+11:00'),
			amount: '-596.64',
			label: 'Dignissim Lacus Aliquam Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '7fe603b3-9a90-456c-aedd-42e7584af5af',
			date: DateTime.fromISO('2021-03-04T00:00:00.000+11:00'),
			amount: '-361.46',
			label: 'Ornare Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e90b5a2f-83b4-46b9-b563-c5c18f9e4259',
			date: DateTime.fromISO('2021-05-18T00:00:00.000+10:00'),
			amount: '-562.24',
			label: 'Dignissim Magna Corporation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '1bdaab18-6068-4cb3-a64a-d9c8325ceb85',
			date: DateTime.fromISO('2021-05-13T00:00:00.000+10:00'),
			amount: '-823.58',
			label: 'Aliquam Vulputate Corporation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '98271e7f-f4bc-461a-8881-8afc51b423f9',
			date: DateTime.fromISO('2021-04-18T00:00:00.000+10:00'),
			amount: '-545.17',
			label: 'Donec Dignissim Incorporated',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'f4fcee80-a85e-46b6-8749-87788e4b7189',
			date: DateTime.fromISO('2020-10-08T00:00:00.000+11:00'),
			amount: '-226.83',
			label: 'Lorem Corporation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '32b0eee9-05db-496b-b196-ccb1fb955c1d',
			date: DateTime.fromISO('2021-04-14T00:00:00.000+10:00'),
			amount: '-275.48',
			label: 'Nisi Mauris Nulla LLC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '22f15d93-d5c4-499b-b58d-4821bf59965c',
			date: DateTime.fromISO('2020-10-01T00:00:00.000+10:00'),
			amount: '-159.54',
			label: 'Ornare Facilisis Eget Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '3fb6c6fe-1a36-414b-a9ed-dd79c8c4a936',
			date: DateTime.fromISO('2020-12-01T00:00:00.000+11:00'),
			amount: '-530.54',
			label: 'Nibh Ltd',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'bcb40841-b1fc-4645-8f8b-7af0f30129c1',
			date: DateTime.fromISO('2020-12-14T00:00:00.000+11:00'),
			amount: '-226.64',
			label: 'Mauris Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'd9e71923-a2de-41a0-b7a2-a961a3ba251d',
			date: DateTime.fromISO('2021-05-30T00:00:00.000+10:00'),
			amount: '-887.65',
			label: 'Est Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'a79732c2-6693-44b9-a417-9a512dfd973b',
			date: DateTime.fromISO('2020-11-05T00:00:00.000+11:00'),
			amount: '-181.26',
			label: 'Adipiscing Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '0a6f615e-5641-41b7-8394-c7048ffeb64a',
			date: DateTime.fromISO('2020-12-13T00:00:00.000+11:00'),
			amount: '-780.02',
			label: 'Purus Accumsan Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '518d86f2-6780-4548-b719-8439036b1439',
			date: DateTime.fromISO('2021-04-01T00:00:00.000+11:00'),
			amount: '-617.07',
			label: 'Dictum Augue Malesuada PC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '3c06bc83-939c-4134-878e-8bf6f0719682',
			date: DateTime.fromISO('2021-04-13T00:00:00.000+10:00'),
			amount: '-960.95',
			label: 'Ipsum Suspendisse Non Inc.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '3afe96eb-3d65-4f0a-a37a-207c1519715a',
			date: DateTime.fromISO('2020-12-05T00:00:00.000+11:00'),
			amount: '-950.58',
			label: 'Cras Industries',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'a9513f61-8c56-4675-946f-b33a048fe5ce',
			date: DateTime.fromISO('2021-03-20T00:00:00.000+11:00'),
			amount: '-532.52',
			label: 'Nulla Interdum Curabitur LLP',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'cd2f1227-359b-4db4-aa62-ab0b21b1bc60',
			date: DateTime.fromISO('2021-02-07T00:00:00.000+11:00'),
			amount: '-963.98',
			label: 'Vitae Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '5f65293a-24d2-4949-b9a0-91be41555980',
			date: DateTime.fromISO('2020-10-20T00:00:00.000+11:00'),
			amount: '-91.37',
			label: 'Et Euismod Et Inc.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '20e1e130-bedd-4151-ace3-c1cd12b232e8',
			date: DateTime.fromISO('2021-05-22T00:00:00.000+10:00'),
			amount: '-155.27',
			label: 'Massa Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '4053c96d-d53b-43c9-aba9-ade5b8d14df5',
			date: DateTime.fromISO('2020-09-29T00:00:00.000+10:00'),
			amount: '-515.87',
			label: 'Ridiculus Mus PC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'dc97df7f-9f1c-4399-9f6b-016f0929b34f',
			date: DateTime.fromISO('2020-07-27T00:00:00.000+10:00'),
			amount: '-629.39',
			label: 'Magna Praesent Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '466dce22-6b57-4310-a2bc-3a1368ff5eb2',
			date: DateTime.fromISO('2021-05-10T00:00:00.000+10:00'),
			amount: '-583.70',
			label: 'Nunc Ullamcorper Industries',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '64a339e3-2f74-4c44-a2a7-6460f97d1f2e',
			date: DateTime.fromISO('2021-01-18T00:00:00.000+11:00'),
			amount: '-764.28',
			label: 'Orci Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'c9d1f154-3eb6-42b4-af08-4664909463d1',
			date: DateTime.fromISO('2020-10-23T00:00:00.000+11:00'),
			amount: '-327.10',
			label: 'Magna Lorem Ipsum Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'bdb2713e-8bf8-41bc-aad6-a0b0dd172d63',
			date: DateTime.fromISO('2021-06-26T00:00:00.000+10:00'),
			amount: '-892.06',
			label: 'Tristique Foundation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '34b844ba-ed56-4743-a32b-89da0ae70d2c',
			date: DateTime.fromISO('2020-09-25T00:00:00.000+10:00'),
			amount: '-718.75',
			label: 'Ridiculus Mus Proin Incorporated',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e4be6dfc-9baf-4c95-8b7e-6c004ec3580d',
			date: DateTime.fromISO('2021-06-05T00:00:00.000+10:00'),
			amount: '-022.06',
			label: 'Nonummy Corporation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '29589cef-da4d-4803-a8d6-293afe269be9',
			date: DateTime.fromISO('2020-07-10T00:00:00.000+10:00'),
			amount: '-933.87',
			label: 'Neque Nullam Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '1f389998-cf21-470c-adef-50f80b4c9e6a',
			date: DateTime.fromISO('2020-10-19T00:00:00.000+11:00'),
			amount: '-253.00',
			label: 'Placerat Associates',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'e1827754-8919-425f-8f7b-f5b5afafc87a',
			date: DateTime.fromISO('2020-12-18T00:00:00.000+11:00'),
			amount: '-707.93',
			label: 'Magna Et Ipsum PC',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'd057251a-3403-4d0f-8f49-156899eb003b',
			date: DateTime.fromISO('2020-09-30T00:00:00.000+10:00'),
			amount: '-445.70',
			label: 'Pede Cras Vulputate Corporation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '5cfa078c-1842-40b0-b781-bebde59a2c1e',
			date: DateTime.fromISO('2020-09-14T00:00:00.000+10:00'),
			amount: '-276.54',
			label: 'Nec Mollis Vitae Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'a6ef0391-80ba-407d-8cbc-c4c4f269150a',
			date: DateTime.fromISO('2021-06-28T00:00:00.000+10:00'),
			amount: '-347.40',
			label: 'Sit Amet Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '033d1140-f691-4954-9d96-98dbdaa60abe',
			date: DateTime.fromISO('2020-10-08T00:00:00.000+11:00'),
			amount: '-223.12',
			label: 'Litora Inc.',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '43fa9952-b345-43db-ae91-5eadf69768bd',
			date: DateTime.fromISO('2021-03-01T00:00:00.000+11:00'),
			amount: '-133.71',
			label: 'Duis Corporation',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '1ae8baff-1baa-4e3e-b38c-8f0fb44b27af',
			date: DateTime.fromISO('2020-07-15T00:00:00.000+10:00'),
			amount: '-837.93',
			label: 'Cras Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'f19dc048-dc06-42c3-ad79-3f253a9cb455',
			date: DateTime.fromISO('2020-12-11T00:00:00.000+11:00'),
			amount: '-271.33',
			label: 'Lacinia At Iaculis Consulting',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: '2c1aa371-9755-421e-bde7-5cc028aa28f3',
			date: DateTime.fromISO('2020-12-21T00:00:00.000+11:00'),
			amount: '-44.26',
			label: 'Suspendisse Institute',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
		{
			UUID: 'c2c8b2e3-9722-465c-b417-54e7e89e2234',
			date: DateTime.fromISO('2020-08-10T00:00:00.000+10:00'),
			amount: '-480.73',
			label: 'Est Mauris Limited',
			isTaxDeductable: false,
			categoryId: undefined,
			itemId: undefined,
		},
	],
};
