import { BUDGETAPP_UPDATE_BUDGET_CATEGORY_ACTIONTYPE } from '../../actionTypes';
import { Category } from '../../Model';

export interface UpdateBudgetCategoryAction {
	readonly type: typeof BUDGETAPP_UPDATE_BUDGET_CATEGORY_ACTIONTYPE,
	budgetId: string;
	category: Category;
}

export const updateBudgetCategory = (
	budgetId: string,
	category: Category,
): UpdateBudgetCategoryAction => ({
	type: BUDGETAPP_UPDATE_BUDGET_CATEGORY_ACTIONTYPE,
	budgetId,
	category,
});
