import { BUDGETAPP_ADD_BUDGET_CATEGORY_ACTIONTYPE } from '../../actionTypes';


export interface AddBudgetCategoryAction {
	readonly type: typeof BUDGETAPP_ADD_BUDGET_CATEGORY_ACTIONTYPE;
	budgetId: string;
}

export const addBudgetCategory = (
	budgetId: string
): AddBudgetCategoryAction => ({
	type: BUDGETAPP_ADD_BUDGET_CATEGORY_ACTIONTYPE,
	budgetId,
});
