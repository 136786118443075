
import { BUDGETAPP_CLOSE_GLOBAL_MODAL_ACTIONTYPE } from '../../actionTypes';

export interface CloseGlobalModalAction {
	readonly type: typeof BUDGETAPP_CLOSE_GLOBAL_MODAL_ACTIONTYPE;
}

export const closeGlobalModal = (
): CloseGlobalModalAction => ({
	type: BUDGETAPP_CLOSE_GLOBAL_MODAL_ACTIONTYPE,
});
