import './MobileBudgetTable.scss';
import React, { useCallback, useState } from 'react';
import {
	Button,
	TextInput,
	Select,
	SelectItem,
	Accordion,
	AccordionItem,
	Checkbox,
} from 'carbon-components-react';
import { Category, FrequencyOptions } from '../../redux/Model';

import { Budget as BudgetObject } from '../../redux/Model';
import { AddAlt24, Save24, TrashCan24 } from '@carbon/icons-react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { addBudgetCategory } from '../../redux/reducers/addBudgetCategory/action';
import { updateBudgetCategory } from '../../redux/reducers/updateBudgetCategory/action';
import deleteBudgetCategory from '../../redux/reducers/deleteBudgetCategory/action';
import selectBudgetTotals from '../../redux/selectors/selectBudgetTotals';
import { numberWithCommas } from '../../utils/numberUtils';
import { saveBudget, selectFrequencySuffix } from '../../utils/BudgetUtils';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import BudgetChart from '../BudgetChart/BudgetChart';
import { deleteBudget } from '../../redux/reducers/deleteBudget/action';
import ActionGuardModal from '../ActionGuardModal/ActionGuardModal';
import MobileBudgetItemTable from './MobileBudgetItemTable/MobileBudgetItemTable';
import { BudgetAppState } from '../../redux/storeModel';

const headers = [
	{
		key: 'total',
		header: 'Total',
	},
	{
		key: 'frequency',
		header: 'View',
	},
];

export interface OwnProps {
	readonly budget: BudgetObject;
}

export type BudgetProps = OwnProps &
	ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;

const MobileBudgetTable: React.FC<BudgetProps> = (props) => {
	const rowData: Array<Category> = props.budget.categories;

	const [frequency, setFrequency] = useState<FrequencyOptions>(
		FrequencyOptions.Annually
	);

	const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

	const totals = selectBudgetTotals(props.budget, frequency);

	const dispatchDeleteBudget = props.dispatchDeleteBudget;
	const budgetId = props.budget.UUID;

	const deleteBudget = useCallback(() => {
		dispatchDeleteBudget(budgetId);
		setConfirmDelete(false);
	}, [dispatchDeleteBudget, budgetId]);

	return (
		<ErrorBoundary>
			<article className="mobile--budget-table-container">
				<ActionGuardModal
					message="Are you sure want to delete this budget?"
					onOk={deleteBudget}
					onCancel={() => {
						setConfirmDelete(false);
					}}
					isOpen={confirmDelete}
				/>
				<div className="mobile--budget-table-actions-row">
					<Button
						hasIconOnly
						renderIcon={Save24}
						iconDescription="Save Budget"
						kind="ghost"
						onClick={() => {
							saveBudget(props.budget, props.transactionLists);
						}}
					/>
					<Button
						className="budget-category-header-item"
						hasIconOnly
						renderIcon={TrashCan24}
						iconDescription="Delete Budget"
						kind="ghost"
						tooltipPosition="top"
						onClick={() => {
							setConfirmDelete(true);
						}}
					/>
				</div>
				<div className="mobile--budget-headers">
					{headers.map((header) => (
						<div className="mobile--budget-header-container" key={header.key}>
							<p>
								{header.header}
								{header.key.includes('total') &&
									` $ ${numberWithCommas(totals.total, 2)}`}
							</p>
							{header.key.includes('frequency') && (
								<Select
									id={`${header.key}-input`}
									value={frequency}
									labelText=""
									onChange={(event) => {
										// Assert type as we guarantee it is a possible value in the enum below.
										setFrequency(event.target.value as FrequencyOptions);
									}}
								>
									{Object.values(FrequencyOptions).map((val) => {
										return (
											<SelectItem text={val} value={val} key={val}></SelectItem>
										);
									})}
								</Select>
							)}
						</div>
					))}
					<Button
						hasIconOnly
						renderIcon={AddAlt24}
						iconDescription="Add Category"
						kind="ghost"
						tooltipPosition="left"
						onClick={() => {
							props.dispatchAddBudgetCategory(props.budget.UUID);
						}}
					/>
				</div>
				<Accordion align="start">
					{rowData.map((category, index) => {
						return (
							<div
								key={category.UUID}
								className="mobile--budget-category-container"
							>
								<AccordionItem
									title={
										<div className="mobile--budget-category-accordion-header">
											<TextInput
												id={`${category.UUID}-input`}
												value={category.name}
												labelText=""
												onChange={(event) => {
													props.dispatchUpdateBudgetCategory(
														props.budget.UUID,
														{
															...props.budget.categories[index],
															name: event.target.value,
														}
													);
												}}
												onClick={(event) => {
													event.preventDefault();
													event.stopPropagation();
												}}
											/>
										</div>
									}
								>
									<div className="mobile--budget-category-header-actions-container">
										<div className="mobile--budget-category-header-item">
											<Checkbox
												id={`${props.budget.UUID}-${category.UUID}-isIncome`}
												aria-label="Is an income category"
												labelText="Is Income"
												checked={category.isIncome}
												key={category.UUID}
												onChange={(value, id, event) => {
													props.dispatchUpdateBudgetCategory(
														props.budget.UUID,
														{
															...category,
															isIncome: value,
														}
													);
												}}
											/>
										</div>
										<Button
											className="mobile--budget-category-header-item"
											hasIconOnly
											renderIcon={TrashCan24}
											iconDescription="Delete Category"
											kind="ghost"
											tooltipPosition="left"
											onClick={(event) => {
												event.preventDefault();
												event.stopPropagation();
												props.dispatchDeleteBudgetCategory(
													props.budget.UUID,
													category.UUID
												);
											}}
										/>
									</div>
									<MobileBudgetItemTable
										budgetId={props.budget.UUID}
										categoryId={category.UUID}
										categoryTotal={totals.categoryTotals.get(category.UUID)}
										items={category.items}
										budgetFrequency={frequency}
									/>
								</AccordionItem>
							</div>
						);
					})}
				</Accordion>
				<div className="mobile--budget-totals-container">
					<p>
						Income Total: $ {numberWithCommas(totals.incomeTotal, 2)}{' '}
						{selectFrequencySuffix(frequency)}
					</p>
					<p>
						Expense Total: $ {numberWithCommas(totals.expenseTotal, 2)}{' '}
						{selectFrequencySuffix(frequency)}
					</p>
					{Math.sign(totals.surplus) === 1 || totals.surplus === 0 ? (
						<p>
							Your budget has a surplus of ${' '}
							{numberWithCommas(totals.surplus, 2)}{' '}
							{selectFrequencySuffix(frequency)}
						</p>
					) : (
						<p>
							Your budget has a deficit of ${' '}
							{numberWithCommas(totals.surplus, 2)}{' '}
							{selectFrequencySuffix(frequency)}
						</p>
					)}
				</div>
				<BudgetChart
					budget={props.budget}
					totals={totals}
					frequency={frequency}
				/>
			</article>
		</ErrorBoundary>
	);
};

const mapStateToProps = (state: BudgetAppState) => ({
	transactionLists: state.transactionLists,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
	return {
		dispatchAddBudgetCategory: (budgetId: string) => {
			dispatch(addBudgetCategory(budgetId));
		},
		dispatchUpdateBudgetCategory: (budgetId: string, category: Category) => {
			dispatch(updateBudgetCategory(budgetId, category));
		},
		dispatchDeleteBudgetCategory: (budgetId: string, categoryId: string) => {
			dispatch(deleteBudgetCategory(budgetId, categoryId));
		},
		dispatchDeleteBudget: (budgetId: string) => {
			dispatch(deleteBudget(budgetId));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileBudgetTable);
