
import { BUDGETAPP_CLOSE_GLOBAL_DIALOG_ACTIONTYPE } from '../../actionTypes';

export interface CloseGlobalDialogAction {
	readonly type: typeof BUDGETAPP_CLOSE_GLOBAL_DIALOG_ACTIONTYPE;
}

export const closeGlobalDialog = (
): CloseGlobalDialogAction => ({
	type: BUDGETAPP_CLOSE_GLOBAL_DIALOG_ACTIONTYPE,
});
