import { AddBudgetItemAction } from "./action";
import { BudgetAppState } from '../../storeModel';
import { findBudgetIndex, findCategoryIndex } from "../reducerUtils";
import { createBudgetItem } from "../../../utils/BudgetUtils";


const reduceAddBudgetItem = (
	prevState: BudgetAppState,
	action: AddBudgetItemAction,
): BudgetAppState => {

	const budgetIndex = findBudgetIndex(prevState.budgets, action.budgetId);

	const budget = prevState.budgets[budgetIndex];

	if (!budget) {
		console.error(`Budget with ID ${action.budgetId} not found!`);
		return prevState;
	}

	const categoryIndex = findCategoryIndex(budget.categories, action.categoryId);

	const category = budget.categories[categoryIndex];

	if (!category) {
		console.error(`Category with ID ${action.categoryId} not found!`);
		return prevState;
	}

	const newItemsList = [...category.items];
	newItemsList.push(createBudgetItem());

	const newCategory = { ...category };
	newCategory.items = newItemsList;

	const newBudget = { ...budget };
	newBudget.categories = [...budget.categories];
	newBudget.categories[categoryIndex] = newCategory;

	const nextState = {
		...prevState,
		budgets: [...prevState.budgets],
	};
	nextState.budgets[budgetIndex] = newBudget;

	return nextState;
}

export default reduceAddBudgetItem;
