import { BUDGETAPP_DELETE_BUDGET_ITEM_ACTIONTYPE } from "../../actionTypes";

export interface DeleteBudgetItemAction {
	readonly type: typeof BUDGETAPP_DELETE_BUDGET_ITEM_ACTIONTYPE;
	budgetId: string;
	categoryId: string;
	budgetItemId: string;
}

export const deleteBudgetItem = (
	budgetId: string,
	categoryId: string,
	budgetItemId: string,
):DeleteBudgetItemAction => ({
	type: BUDGETAPP_DELETE_BUDGET_ITEM_ACTIONTYPE,
	budgetId,
	categoryId,
	budgetItemId,
});

