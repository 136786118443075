import { Modal } from 'carbon-components-react';
import React from 'react';

export interface ActionGuardModalProps {
	message: string;
	onOk: () => void;
	onCancel: () => void;
	isOpen: boolean;
}

const ActionGuardModal: React.FC<ActionGuardModalProps> = (props) => {
	return (
		<Modal
			danger
			open={props.isOpen}
			onRequestSubmit={props.onOk}
			onRequestClose={props.onCancel}
			onSecondarySubmit={props.onCancel}
			primaryButtonText="Ok"
			secondaryButtonText="Cancel"
			modalHeading={props.message}
			size='xs'
		/>
	)
}


export default ActionGuardModal;
