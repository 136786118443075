import './LandingPage.scss';

import React from 'react';
import {
	Column,
	Grid,
	Row,
	Tabs,
	Tab,
	Button,
} from 'carbon-components-react';

const LandingPage: React.FC = () => {

	const props = {
		tabs: {
			selected: 0,
			role: 'navigation',
		},
		aboutTab: {
			role: 'presentation',
			tabIndex: 0,
			label: 'About'
		},
		designTab: {
			role: 'presentation',
			tabIndex: 1,
			label: 'Design'
		},
		developTab: {
			role: 'presentation',
			tabIndex: 2,
			label: 'Develop'
		},
	};

	return (
		<Grid fullWidth={true} className="landing-page ">

			<Row className="landing-page__banner">
				<Column lg={16}>
					<h1 className="landing-page__heading">Take Control with Simple Budget</h1>
				</Column>
			</Row>

			<Row className="landing-page__r2">

				<Tabs {...props.tabs} aria-label="Tab navigation">

					<Tab {...props.aboutTab}>
						<Grid fullWidth={true} className="bx--grid--no-gutter">
							<Row className="landing-page__tab-content">
								<Column md={4} lg={7} >
									<h2 className="landing-page__subheading">
										What is Simple Budget?
									</h2>
									<p className="landing-page__p">
										Simple Budget is a free tool that makes it easy to take control
										of your finances, empowering you to make financial decisions
										with confidence.
									</p>
									<Button
										href='/#/budget'
									>
										Get Started
									</Button>
								</Column>
								<Column md={4} lg={{span: 8, offset: 1}}>
									<img
										className="landing-page__illo"
										src={`${process.env.PUBLIC_URL}/tab-illo.png`}
										alt="Carbon illustration"
									/>
								</Column>
							</Row>
						</Grid>
					</Tab>

					{/* <Tab className='landing-page__tab' {...props.designTab}>
						<Grid fullWidth={true} className="bx--grid--no-gutter">
							<Row className="landing-page__tab-content">
								<Column lg={16}>
									Rapidly build beautiful and accessible experiences.
									The Carbon kit contains all resources you need to get started.
								</Column>
							</Row>
						</Grid>
					</Tab>

					<Tab {...props.developTab} >
						<Grid fullWidth={true} className="bx--grid--no-gutter">
							<Row className="landing-page__tab-content">
								<Column lg={16}>
									Carbon provides styles and components in Vanilla, React, Angular,
									and Vue for anyone building on the web.
								</Column>
							</Row>
						</Grid>
					</Tab> */}

				</Tabs>

			</Row>

			{/* <Row className="landing-page__r3">
				<Column md={16} lg={4}>
					<h3 className="landing-page__label">The Principles</h3>
				</Column>
				<Column md={4} lg={4}>
					Carbon is Open
				</Column>
				<Column md={4} lg={4}>
					Carbon is Modular
				</Column>
				<Column md={4} lg={4}>
					Carbon is Consistent
				</Column>
			</Row> */}

		</Grid>
	);
};

export default LandingPage;
