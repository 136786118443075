import { BudgetAppState } from '../../storeModel';
import { AddTransactionListAction } from './action';

const reduceAddTransactionList = (
	prevState: BudgetAppState,
	action: AddTransactionListAction,
): BudgetAppState => {
	const newTransactions = [...prevState.transactionLists];

	const existingListIndex = newTransactions.findIndex(list => (list.budgetId === action.budgetId));
	const existingList = newTransactions[existingListIndex];

	// If there is an existing list for the transactions we're importing,
	// we need to remove some possible duplicates
	if(existingList) {
		const transactionsToAdd = action.transactionList.transactions.filter(transaction => {
			const matchingTransaction = existingList.transactions.find(_transaction => {

				return (
					_transaction.amount === transaction.amount &&
					_transaction.date.equals(transaction.date) &&
					_transaction.label === transaction.label
				);
			});
			return matchingTransaction === undefined;
		});
		const finalList = existingList.transactions.concat(transactionsToAdd);
		newTransactions[existingListIndex] = {...existingList, transactions: finalList};
	} else {
		newTransactions.push(action.transactionList);
	}



	return {
		...prevState,
		transactionLists: newTransactions,
	}
}

export default reduceAddTransactionList;

