import { BUDGETAPP_DELETE_TRANSACTION_LIST } from '../../actionTypes';

export interface DeleteTransactionListAction {
	readonly type: typeof BUDGETAPP_DELETE_TRANSACTION_LIST;
	transactionListId: string;
}

export const deleteTransactionList = (
	transactionListId: string,
): DeleteTransactionListAction => ({
	type: BUDGETAPP_DELETE_TRANSACTION_LIST,
	transactionListId,
});

