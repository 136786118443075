import { BudgetAppState } from '../../storeModel';
import { UpdateBudgetCategoryAction } from './action';
import { findBudgetIndex, findCategoryIndex } from '../reducerUtils';
import { ItemSpendType } from '../../Model';

export const reduceUpdateBudgetCategory = (
	prevState: BudgetAppState,
	action: UpdateBudgetCategoryAction
): BudgetAppState => {
	const budgetIndex = findBudgetIndex(prevState.budgets, action.budgetId);

	const budget = prevState.budgets[budgetIndex];

	const newBudget = { ...budget };

	if (!newBudget) {
		console.error(`Budget with ID ${action.budgetId} not found!`);
		return prevState;
	}

	const categoryIndex = findCategoryIndex(
		newBudget.categories,
		action.category.UUID
	);

	newBudget.categories = [...newBudget.categories];
	newBudget.categories[categoryIndex] = action.category;

	if (action.category.isIncome) {
		newBudget.categories[categoryIndex].items = newBudget.categories[
			categoryIndex
		].items.map((item) => ({ ...item, spendType: ItemSpendType.Income }));
	}

	const newState = { ...prevState };
	newState.budgets = [...newState.budgets];
	newState.budgets[budgetIndex] = newBudget;

	return newState;
};
