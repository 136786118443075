import { AddBudgetCategoryAction } from './action';
import { BudgetAppState } from '../../storeModel';
import { findBudgetIndex } from '../reducerUtils';
import { createBudgetCategory } from '../../../utils/BudgetUtils';

const reduceAddBudgetCategory = (
	prevState: BudgetAppState,
	action: AddBudgetCategoryAction
): BudgetAppState => {

	const budgetIndex = findBudgetIndex(prevState.budgets, action.budgetId);

	const budget = prevState.budgets[budgetIndex];

	if (!budget) {
		console.error(`Budget with ID ${action.budgetId} not found!`);
		return prevState;
	}

	const newBudget = { ...budget, categories: [...budget.categories] };
	newBudget.categories.push(createBudgetCategory());

	const newBudgetsList = [...prevState.budgets];
	newBudgetsList[budgetIndex] = newBudget;

	const nextState = { ...prevState, budgets: newBudgetsList };

	return nextState;
};

export default reduceAddBudgetCategory;
