import './BudgetChart.scss';

import { Budget, BudgetTotals, FrequencyOptions } from '../../redux/Model';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	// Legend,
	ResponsiveContainer,
} from 'recharts';
import { useEffect, useState } from 'react';
import { findCategoryIndex } from '../../redux/reducers/reducerUtils';
import { Button } from 'carbon-components-react';
import {
	calcAnnualValue,
	calcQuarterlyValue,
	calcMonthlyValue,
	calcFortnightlyValue,
	calcWeeklyValue,
} from '../../utils/BudgetUtils';

export interface BudgetChartProps {
	budget: Budget;
	totals: BudgetTotals;
	frequency: FrequencyOptions;
}
const BudgetChart: React.FC<BudgetChartProps> = (props) => {
	const [isDrilldown, setIsDrilldown] = useState<boolean>(false);
	const [drilldownId, setDrilldownId] = useState<string | null>(null);

	const data: Array<any> = [];

	useEffect(() => {
		if (isDrilldown && !drilldownId) {
			setIsDrilldown(false);
			setDrilldownId(null);
		}

		if (
			isDrilldown &&
			drilldownId &&
			(!props.totals.categoryTotals.has(drilldownId) ||
				props.budget.categories[
					findCategoryIndex(props.budget.categories, drilldownId)
				].isIncome)
		) {
			setIsDrilldown(false);
			setDrilldownId(null);
		}
	}, [
		isDrilldown,
		drilldownId,
		props.totals.categoryTotals,
		props.budget.categories,
	]);

	let category = null;

	if (isDrilldown && drilldownId) {
		category =
			props.budget.categories[
				findCategoryIndex(props.budget.categories, drilldownId)
			];

		category.items.forEach((item) => {
			let value = 0;
			switch (props.frequency) {
				case FrequencyOptions.Annually: {
					value = calcAnnualValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
				case FrequencyOptions.Quarterly: {
					value = calcQuarterlyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
				case FrequencyOptions.Monthly: {
					value = calcMonthlyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
				case FrequencyOptions.Fortnightly: {
					value = calcFortnightlyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
				case FrequencyOptions.Weekly: {
					value = calcWeeklyValue(
						item.value,
						item.frequency as FrequencyOptions
					);
					break;
				}
			}

			data.push({
				name: item.name,
				total: Number.parseFloat(value.toFixed(2)),
				id: item.UUID,
			});
		});
	} else {
		props.budget.categories.forEach((category) => {
			if (!category.isIncome) {
				data.push({
					name: category.name,
					total:
						Number.parseFloat(
							(props.totals.categoryTotals.get(category.UUID) || 0).toFixed(2)
						) * -1,
					id: category.UUID,
				});
			}
		});
	}

	return (
		<div className="budget-chart-container">
			<div className="budget-chart-headers-container">
				<h2 className="budget-chart-title">
					Budget Breakdown{' '}
					{isDrilldown && category !== null && ` - ${category.name}`}
				</h2>
				{isDrilldown && (
					<Button
						kind="primary"
						onClick={() => {
							setIsDrilldown(false);
							setDrilldownId(null);
						}}
					>
						Back
					</Button>
				)}
			</div>
			<ResponsiveContainer width="100%" height={500}>
				<BarChart width={500} height={300} data={data}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					{/* <Legend /> */}
					<Bar
						dataKey="total"
						fill="#8884d8"
						onClick={(evt) => {
							if (isDrilldown) {
								return;
							}
							setIsDrilldown(true);
							setDrilldownId(evt.id);
						}}
					/>
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

export default BudgetChart;
