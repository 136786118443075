import React from 'react';
import './ErrorBoundary.scss';

export interface ErrorBoundaryProps {
	fallback?: React.ReactNode;
	dataToLog?: any;
}

export interface ErrorBoundaryState {
	hasError: boolean;
	error: Error | null;
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {

	constructor (props: any) {
		super(props);
		this.state = { hasError: false, error: null};
	}

	static getDerivedStateFromError(error: any) {
		console.error(error);
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any) {

		if(this.props.dataToLog) {
			console.log(this.props.dataToLog);
		}

		console.error(error, errorInfo);
	}

	render() {
		if(this.state.hasError) {
			if(this.props.fallback) {
				console.log('render fallback');
				return this.props.fallback;
			} else {
				console.log('no fallback');
				return (
					<>
						<h1>Something Went Wrong</h1>
					</>
				);
			}
		} else {
			return this.props.children;
		}

	}
}
