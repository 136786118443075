import BudgetTable from '../../components/Budget/BudgetTable';
import MobileBudgetTable from '../../components/MobileBudgetTable/MobileBudgetTable';
import { Budget } from '../../redux/Model';

interface PageBodyProps {
	isMobileView: boolean;
	budget: Budget | undefined;
}

const BudgetPageBody: React.FC<PageBodyProps> = (props) => {
	if (props.budget === undefined) {
		return (
			<div>
				<p>No budget selected!</p>
			</div>
		);
	}

	if (props.isMobileView) {
		return <MobileBudgetTable key={props.budget.UUID} budget={props.budget} />;
	} else {
		return <BudgetTable budget={props.budget} />;
	}
};

export default BudgetPageBody;
